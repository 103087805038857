export const DEFAULT_LOCALE = "en_GB";
export const IS_LOCAL =
  process.env.REACT_APP_IS_LOCAL === "true" ? true : false;
export const BASEPATH = process.env.REACT_APP_BASEPATH;
export const SESSION_DIVISOR = process.env.REACT_APP_PLAYER_SESSION_DIVISOR;
export const LOGIN_OPERATION_TYPE_ID = 1;
export const LOGIN_ACTION_DESCRIPTION = "Login";
export const LOGIN_FAILED_OPERATION_TYPE_ID = 5;
export const LOGIN_FAILED_ACTION_DESCRIPTION = "Login Failed";
export const LOGOUT_OPERATION_TYPE_ID = 2;
export const LOGOUT_ACTION_DESCRIPTION = "Logout";
export const LOGOUT_FAILED_OPERATION_TYPE_ID = 6;
export const LOGOUT_FAILED_ACTION_DESCRIPTION = "Logout Failed";
export const TOGGLE_THEME = "TOGGLE_THEME";
export const TOGGLE_HC = "TOGGLE_HC";
export const SET_LOCALE = "SET_LOCALE";
export const INIT_LOCALE = "INIT_LOCALE";
export const LANGUAGES_ACTION = "LANGUAGES_ACTION";
export const debug = process.env.REACT_APP_DEBUG === "true" ? true : false;
export const EXTERNAL_URL_PATH = "/ExternalUrl/";
export const OPEN_WINDOW_URL_PATH = "/OpenWindow/";
export const CAMPAIGN_BACK_OFFICE_TYPE_AMOUNT = "AMOUNT";
export const NETWORK_BANS_ALL_GAMES = "-9999";
export const NO_OPERATOR = "NO_OPERATOR";
export const CONTROL_EXTERNAL_SYSTEM_ID = 2;
export const MAX_INTEGER_NUMBER = 999;
export const EXPORT_ACTION_DESCRIPTION = "exportData";
export const EXPORT_OPERATION_TYPE_ID = 3;
export const ADD_NOTIFICATION_TEMPLATE_MAX = 70;
export const MAX_LENGTH_FOR_NAME_AND_DESCRIPTION = 1014;
export const ENTRA_REDIRECT_URI = "/igtcontrol/externalOauthLogin";
export const ENTRA_PROVIDER = "MICROSOFT-ENTRA";
//Actions constants

export const UNLOCK_USER_REQUEST = "UNLOCK_USER_REQUEST";
export const UNLOCK_USER_SUCCESS = "UNLOCK_USER_SUCCESS";
export const UNLOCK_USER_FAILURE = "UNLOCK_USER_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const USER_SET_HOME_PG_REQUEST = "USER_SET_HOME_PG_REQUEST";
export const USER_SET_HOME_PG_SUCCESS = "USER_SET_HOME_PG_SUCCESS";
export const USER_SET_HOME_PG_FAILURE = "USER_SET_HOME_PG_FAILURE";

export const USER_CHANGE_PWD_REQUEST = "USER_CHANGE_PWD_REQUEST";
export const USER_CHANGE_PWD_SUCCESS = "USER_CHANGE_PWD_SUCCESS";
export const USER_CHANGE_PWD_FAILURE = "USER_CHANGE_PWD_FAILURE";

export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILURE = "EDIT_USER_FAILURE";

export const PARSE_MULTIPLE_REQUEST = "PARSE_MULTIPLE_REQUEST";
export const PARSE_MULTIPLE_SUCCESS = "PARSE_MULTIPLE_SUCCESS";
export const PARSE_MULTIPLE_FAILURE = "PARSE_MULTIPLE_FAILURE";

export const ADD_SINGLE_REQUEST = "ADD_SINGLE_REQUEST";
export const ADD_SINGLE_SUCCESS = "ADD_SINGLE_SUCCESS";
export const ADD_SINGLE_FAILURE = "ADD_SINGLE_FAILURE";

export const ADD_MULTIPLE_REQUEST = "ADD_MULTIPLE_REQUEST";
export const ADD_MULTIPLE_SUCCESS = "ADD_MULTIPLE_SUCCESS";
export const ADD_MULTIPLE_FAILURE = "ADD_MULTIPLE_FAILURE";

export const LOAD_ROLES_REQUEST = "LOAD_ROLES_REQUEST";
export const LOAD_ROLES_SUCCESS = "LOAD_ROLES_SUCCESS";
export const LOAD_ROLES_FAILURE = "LOAD_ROLES_FAILURE";

export const USER_DETAIL_REQUEST = "USER_DETAIL_REQUEST";
export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS";
export const USER_DETAIL_FAILURE = "USER_DETAIL_FAILURE";

export const LOAD_USERS_REQUEST = "LOAD_USERS_REQUEST";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const LOAD_USERS_FAILURE = "LOAD_USERS_FAILURE";

export const LOAD_ADMIN_LIMITCONFIGURATION_REQUEST =
  "LOAD_ADMIN_LIMITCONFIGURATION_REQUEST";
export const LOAD_ADMIN_LIMITCONFIGURATION_SUCCESS =
  "LOAD_ADMIN_LIMITCONFIGURATION_SUCCESS";
export const LOAD_ADMIN_LIMITCONFIGURATION_FAILURE =
  "LOAD_ADMIN_LIMITCONFIGURATION_FAILURE";
export const DELETE_LIMITCONFIGURATION_REQUEST =
  "DELETE_LIMITCONFIGURATION_REQUEST";
export const DELETE_LIMITCONFIGURATION_FAILURE =
  "DELETE_LIMITCONFIGURATION_FAILURE";
export const ENABLE_OR_DISABLE_LIMITCONFIGURATION =
  "ENABLE_OR_DISABLE_LIMITCONFIGURATION";
export const ENABLE_OR_DISABLE_LIMITCONFIGURATION_FAILURE =
  "ENABLE_OR_DISABLE_LIMITCONFIGURATION_FAILURE";
export const ADMIN_LIMIT_TYPES_REQUEST = "ADMIN_LIMIT_TYPES_REQUEST";
export const ADMIN_LIMIT_TYPES_SUCCESS = "ADMIN_LIMIT_TYPES_SUCCESS";
export const ADMIN_LIMIT_TYPES_FAILURE = "ADMIN_LIMIT_TYPES_FAILURE";
export const NOT_APPROVATOR_ROLES_REQUEST = "NOT_APPROVATOR_ROLES_REQUEST";
export const NOT_APPROVATOR_ROLES_SUCCESS = "NOT_APPROVATOR_ROLES_SUCCESS";
export const NOT_APPROVATOR_ROLES_FAILURE = "NOT_APPROVATOR_ROLES_FAILURE";
export const LIMIT_REWARD_TYPES_REQUEST = "LIMIT_REWARD_TYPES_REQUEST";
export const LIMIT_REWARD_TYPES_SUCCESS = "LIMIT_REWARD_TYPES_SUCCESS";
export const LIMIT_REWARD_TYPES_FAILURE = "LIMIT_REWARD_TYPES_FAILURE";
export const ADD_LIMITCONFIGURATION_REQUEST = "ADD_LIMITCONFIGURATION_REQUEST";
export const ADD_LIMITCONFIGURATION_SUCCESS = "ADD_LIMITCONFIGURATION_SUCCESS";
export const ADD_LIMITCONFIGURATION_FAILURE = "ADD_LIMITCONFIGURATION_FAILURE";
export const EDIT_LIMITCONFIGURATION_REQUEST =
  "EDIT_LIMITCONFIGURATION_REQUEST";
export const EDIT_LIMITCONFIGURATION_SUCCESS =
  "EDIT_LIMITCONFIGURATION_SUCCESS";
export const EDIT_LIMITCONFIGURATION_FAILURE =
  "EDIT_LIMITCONFIGURATION_FAILURE";

export const GET_USER_SECURITY_SETTINGS = "GET_USER_SECURITY_SETTINGS";
export const GET_USER_SECURITY_SETTINGS_SUCCESS =
  "GET_USER_SECURITY_SETTINGS_SUCCESS";
export const GET_USER_SECURITY_SETTINGS_FAILURE =
  "GET_USER_SECURITY_SETTINGS_FAILURE";
export const SAVE_USER_SECURITY_SETTINGS_REQUEST =
  "SAVE_USER_SECURITY_SETTINGS_REQUEST";
export const SAVE_USER_SECURITY_SETTINGS_SUCCESS =
  "SAVE_USER_SECURITY_SETTINGS_SUCCESS";
export const SAVE_USER_SECURITY_SETTINGS_FAILURE =
  "SAVE_USER_SECURITY_SETTINGS_FAILURE";

export const PLAYER_DOCUMENTS_REQUEST = "PLAYER_DOCUMENTS_REQUEST";
export const PLAYER_DOCUMENTS_SUCCESS = "PLAYER_DOCUMENTS_SUCCESS";
export const PLAYER_DOCUMENTS_FAILURE = "PLAYER_DOCUMENTS_FAILURE";

export const PLAYER_DOCUMENTS_HISTORY_REQUEST =
  "PLAYER_DOCUMENTS_HISTORY_REQUEST";
export const PLAYER_DOCUMENTS_HISTORY_SUCCESS =
  "PLAYER_DOCUMENTS_HISTORY_SUCCESS";
export const PLAYER_DOCUMENTS_HISTORY_FAILURE =
  "PLAYER_DOCUMENTS_HISTORY_FAILURE";

export const LOTTERY_BLOCKLIST_REQUEST = "LOTTERY_BLOCKLIST_REQUEST";
export const LOTTERY_BLOCKLIST_SUCCESS = "LOTTERY_BLOCKLIST_SUCCESS";
export const LOTTERY_BLOCKLIST_FAILURE = "LOTTERY_BLOCKLIST_FAILURE";

export const REMOVE_FROM_BLOCKLIST_REQUEST = "REMOVE_FROM_BLOCKLIST_REQUEST";
export const REMOVE_FROM_BLOCKLIST_SUCCESS = "REMOVE_FROM_BLOCKLIST_SUCCESS";
export const REMOVE_FROM_BLOCKLIST_FAILURE = "REMOVE_FROM_BLOCKLIST_FAILURE";

export const LOTTERY_BLOCKLIST_FILTER_REQUEST =
  "LOTTERY_BLOCKLIST_FILTER_REQUEST";
export const LOTTERY_BLOCKLIST_FILTER_SUCCESS =
  "LOTTERY_BLOCKLIST_FILTER_SUCCESS";
export const LOTTERY_BLOCKLIST_FILTER_FAILURE =
  "LOTTERY_BLOCKLIST_FILTER_FAILURE";

export const ADD_EDIT_TO_BLOCKLIST_REQUEST = "ADD_EDIT_TO_BLOCKLIST_REQUEST";
export const ADD_EDIT_TO_BLOCKLIST_SUCCESS = "ADD_EDIT_TO_BLOCKLIST_SUCCESS";
export const ADD_EDIT_TO_BLOCKLIST_FAILURE = "ADD_EDIT_TO_BLOCKLIST_FAILURE";

export const DOCUMENT_TYPES_REQUEST = "DOCUMENT_TYPES_REQUEST";
export const DOCUMENT_TYPES_SUCCESS = "DOCUMENT_TYPES_SUCCESS";
export const DOCUMENT_TYPES_FAILURE = "DOCUMENT_TYPES_FAILURE";

export const ADD_DOCUMENT_REQUEST = "ADD_DOCUMENT_REQUEST";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const ADD_DOCUMENT_FAILURE = "ADD_DOCUMENT_FAILURE";

export const REFRESH_SIDE_MENU_ACTION = "REFRESH_SIDE_MENU_ACTION";
export const REFRESH_TREE_MENU_REQUEST = "REFRESH_TREE_MENU_REQUEST";
export const EXTERNAL_URL_ACTION = "EXTERNAL_URL_ACTION";
export const EXTERNAL_URL_REFRESH_KEY_ACTION =
  "EXTERNAL_URL_REFRESH_KEY_ACTION";
export const EXTERNAL_URL_REQUEST = "EXTERNAL_URL_REQUEST";
export const ADD_BOOKMARKS_REQUEST = "ADD_BOOKMARKS_REQUEST";
export const REMOVE_BOOKMARKS_REQUEST = "REMOVE_BOOKMARKS_REQUEST";
export const GET_BOOKMARKS_REQUEST = "GET_BOOKMARKS_REQUEST";
export const ADD_BOOKMARKS_ACTION = "ADD_BOOKMARKS_ACTION";
export const REMOVE_BOOKMARKS_ACTION = "REMOVE_BOOKMARKS_ACTION";
export const GET_BOOKMARKS_ACTION = "GET_BOOKMARKS_ACTION";
export const LOCALE_REQUEST = "LOCALE_REQUEST";
export const LOCALE_ACTION = "LOCALE_ACTION";
export const LANGUAGES_REQUEST = "LANGUAGES_REQUEST";
export const AUTHENTICATION_REQUEST = "AUTHENTICATION_REQUEST";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const PW_RESET_REQUEST = "PW_RESET_REQUEST";
export const SENDMAIL_REQUEST = "SENDMAIL_REQUEST";
export const SENDMAIL_RESET = "SENDMAIL_RESET";
export const PWRECOVERY_REQUEST = "PWRECOVERY_REQUEST";
export const SESSION_INFO_REQUEST = "SESSION_INFO_REQUEST";
export const PWCHANGE_REQUEST = "PWCHANGE_REQUEST";
export const LOGIN_ACTION = "LOGIN_ACTION";
export const LOGOUT_ACTION = "LOGOUT_ACTION";
export const PWRESET_ACTION = "PWRESET_ACTION";
export const PWRESET_FAILURE = "PWRESET_FAILURE";
export const SENDMAIL_ACTION = "SENDMAIL_ACTION";
export const PWRECOVERY_ACTION = "PWRECOVERY_ACTION";
export const EXPIRE_COOCKIES_ACTION = "EXPIRE_COOCKIES_ACTION";
export const SESSION_INFO_ACTION = "SESSION_INFO_ACTION";
export const PWCHANGE_ACTION = "PWCHANGE_ACTION";
export const UPDATE_BRAND_PARTNER_ACTION = "UPDATE_BRAND_PARTNER_ACTION";
export const HOME_DASHBOARD_DATA_REQUEST = "HOME_DASHBOARD_DATA_REQUEST";
export const HOME_DASHBOARD_DATA_SUCCESS = "HOME_DASHBOARD_DATA_SUCCESS";
export const HOME_DASHBOARD_DATA_FAILURE = "HOME_DASHBOARD_DATA_FAILURE";
export const PAGE_ACTIONS_REQUEST = "PAGE_ACTIONS_REQUEST";
export const PAGE_ACTIONS_SUCCESS = "PAGE_ACTIONS_SUCCESS";
export const PAGE_ACTIONS_FAILURE = "PAGE_ACTIONS_FAILURE";
export const PAGE_ACTIONS_RESET = "PAGE_ACTIONS_RESET";
export const PLAYERS_SEARCH_CRITERIA_REQUEST =
  "PLAYERS_SEARCH_CRITERIA_REQUEST";
export const PLAYERS_SEARCH_CRITERIA_SUCCESS =
  "PLAYERS_SEARCH_CRITERIA_SUCCESS";
export const PLAYERS_SEARCH_CRITERIA_FAILURE =
  "PLAYERS_SEARCH_CRITERIA_FAILURE";
export const PLAYERS_SEARCH_REQUEST = "PLAYERS_SEARCH_REQUEST";
export const PLAYERS_SEARCH_SUCCESS = "PLAYERS_SEARCH_SUCCESS";
export const PLAYERS_SEARCH_CONTRACT_HEADER_SUCCESS =
  "PLAYERS_SEARCH_CONTRACT_HEADER_SUCCESS";
export const BULK_OPERATION_LIST_SEARCH_CRITERIA_REQUEST =
  "BULK_OPERATION_LIST_SEARCH_CRITERIA_REQUEST";
export const BULK_OPERATION_LIST_SEARCH_CRITERIA_SUCCESS =
  "BULK_OPERATION_LIST_SEARCH_CRITERIA_SUCCESS";
export const BULK_OPERATION_LIST_SEARCH_CRITERIA_FAILURE =
  "BULK_OPERATION_LIST_SEARCH_CRITERIA_FAILURE";
export const BULK_OPERATION_LIST_RELOAD = "BULK_OPERATION_LIST_RELOAD";
export const BULK_OPERATION_LIST_REQUEST = "BULK_OPERATION_LIST_REQUEST";
export const BULK_OPERATION_LIST_SUCCESS = "BULK_OPERATION_LIST_SUCCESS";
export const BULK_OPERATION_LIST_VIEW_RELOAD =
  "BULK_OPERATION_LIST_VIEW_RELOAD";
export const BULK_OPERATION_LIST_VIEW_REQUEST =
  "BULK_OPERATION_LIST_VIEW_REQUEST";
export const BULK_OPERATION_LIST_VIEW_SUCCESS =
  "BULK_OPERATION_LIST_VIEW_SUCCESS";
export const BULK_OPERATION_LIST_VIEW_FAILURE =
  "BULK_OPERATION_LIST_VIEW_FAILURE";
export const BULK_OPERATION_TRANSACTIONS_REQUEST =
  "BULK_OPERATION_TRANSACTIONS_REQUEST";
export const BULK_OPERATION_TRANSACTIONS_SUCCESS =
  "BULK_OPERATION_TRANSACTIONS_SUCCESS";
export const BULK_OPERATION_TRANSACTIONS_FAILURE =
  "BULK_OPERATION_TRANSACTIONS_FAILURE";
export const WIDGET_LAYOUT_REQUEST = "WIDGET_LAYOUT_REQUEST";
export const WIDGET_LAYOUT_SUCCESS = "WIDGET_LAYOUT_SUCCESS";
export const WIDGET_REWARD_DATA_REQUEST = "WIDGET_REWARD_DATA_REQUEST";
export const WIDGET_LOY_LEVELS_DATA_REQUEST = "WIDGET_LOY_LEVELS_DATA_REQUEST";
export const WIDGET_HOME_DATA_REQUEST = "WIDGET_HOME_DATA_REQUEST";
export const WIDGET_REPORT_DATA_REQUEST = "WIDGET_REPORT_DATA_REQUEST";
export const WIDGET_RECENTLY_DATA_REQUEST = "WIDGET_RECENTLY_DATA_REQUEST";
export const WIDGET_DATA_SUCCESS = "WIDGET_DATA_SUCCESS";
export const WIDGET_DATA_FAILURE = "WIDGET_DATA_FAILURE";
export const GAME_PLATFORMS_REQUEST = "GAME_PLATFORMS_REQUEST";
export const GAME_PLATFORMS_SUCCESS = "GAME_PLATFORMS_SUCCESS";
export const PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_CRITERIA_REQUEST =
  "PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_CRITERIA_REQUEST";
export const PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_CRITERIA_SUCCESS =
  "PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_CRITERIA_SUCCESS";
export const PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_CRITERIA_FAILURE =
  "PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_CRITERIA_FAILURE";
export const PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_REQUEST =
  "PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_REQUEST";
export const PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_SUCCESS =
  "PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_SUCCESS";
export const PLAYERS_GROUP_LIST_REQUEST = "PLAYERS_GROUP_LIST_REQUEST";
export const DELETE_PLAYERS_GROUP_REQUEST = "DELETE_PLAYERS_GROUP_REQUEST";
export const PLAYERS_GROUP_LIST_SUCCESS = "PLAYERS_GROUP_LIST_SUCCESS";
export const DELETE_PLAYERS_GROUP = "DELETE_PLAYERS_GROUP";
export const EXPIRING_BONUS_SEARCH_REQUEST = "EXPIRING_BONUS_SEARCH_REQUEST";
export const EXPIRING_BONUS_SEARCH_SUCCESS = "EXPIRING_BONUS_SEARCH_SUCCESS";
export const EXPIRING_BONUS_SEARCH_FAILURE = "EXPIRING_BONUS_SEARCH_FAILURE";
export const BULK_OPERATION_LIST_FAILURE = "BULK_OPERATION_LIST_FAILURE";
export const PLAYERS_GROUP_LIST_FAILURE = "PLAYERS_GROUP_LIST_FAILURE";
export const PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_FAILURE =
  "PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_FAILURE";
export const PLAYERS_SEARCH_FAILURE = "PLAYERS_SEARCH_FAILURE";
export const PLAYERS_GROUP_VIEW_REQUEST = "PLAYERS_GROUP_VIEW_REQUEST";
export const PLAYERS_GROUP_VIEW_SUCCESS = "PLAYERS_GROUP_VIEW_SUCCESS";
export const PLAYERS_GROUP_VIEW_FAILURE = "PLAYERS_GROUP_VIEW_FAILURE";
export const PLAYERS_GROUP_DELETE_FAILURE = "PLAYERS_GROUP_DELETE_FAILURE";
export const EXPIRING_BONUS_VIEW_REQUEST = "EXPIRING_BONUS_VIEW_REQUEST";
export const EXPIRING_BONUS_VIEW_SUCCESS = "EXPIRING_BONUS_VIEW_SUCCESS";
export const EXPIRING_BONUS_VIEW_FAILURE = "EXPIRING_BONUS_VIEW_FAILURE";
export const EXPIRING_BONUS_CREATE_REQUEST = "EXPIRING_BONUS_CREATE_REQUEST";
export const EXPIRING_BONUS_CREATE_SUCCESS = "EXPIRING_BONUS_CREATE_SUCCESS";
export const EXPIRING_BONUS_CREATE_FAILURE = "EXPIRING_BONUS_CREATE_FAILURE";
export const EXPIRING_BONUS_PLAYER_LIST_REQUEST =
  "EXPIRING_BONUS_PLAYER_LIST_REQUEST";
export const EXPIRING_BONUS_PLAYER_LIST_SUCCESS =
  "EXPIRING_BONUS_PLAYER_LIST_SUCCESS";
export const EXPIRING_BONUS_PLAYER_LIST_FAILURE =
  "EXPIRING_BONUS_PLAYER_LIST_FAILURE";
export const GAME_CODES_REQUEST = "GAME_CODES_REQUEST";
export const GAME_CODES_SUCCESS = "GAME_CODES_SUCCESS";
export const PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_REQUEST =
  "PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_REQUEST";
export const PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_SUCCESS =
  "PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_SUCCESS";
export const PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_FAILURE =
  "PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_FAILURE";
export const INDIVIDUAL_PLAYER_LOGIN_HISTORY_REQUEST =
  "INDIVIDUAL_PLAYER_LOGIN_HISTORY_REQUEST";
export const INDIVIDUAL_PLAYER_LOGIN_HISTORY_SUCCESS =
  "INDIVIDUAL_PLAYER_LOGIN_HISTORY_SUCCESS";
export const INDIVIDUAL_PLAYER_LOGIN_HISTORY_FAILURE =
  "INDIVIDUAL_PLAYER_LOGIN_HISTORY_FAILURE";
export const INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_REQUEST =
  "INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_REQUEST";
export const INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_SUCCESS =
  "INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_SUCCESS";
export const INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_FAILURE =
  "INDIVIDUAL_PLAYER_CUSTOMER_ACCOUNT_UPDATE_FAILURE";
export const PLAYERS_GROUP_CREATE_REQUEST = "PLAYERS_GROUP_CREATE_REQUEST";
export const CONTRACT_TYPE_REQUEST = "CONTRACT_TYPE_REQUEST";
export const CONTRACT_TYPE_SUCCESS = "CONTRACT_TYPE_SUCCESS";
export const INDIVIDUAL_PLAYER_GROUPS_SUCCESS =
  "INDIVIDUAL_PLAYER_GROUPS_SUCCESS";
export const INDIVIDUAL_PLAYER_GROUPS_FAILURE =
  "INDIVIDUAL_PLAYER_GROUPS_FAILURE";
export const INDIVIDUAL_PLAYER_GROUPS_REQUEST =
  "INDIVIDUAL_PLAYER_GROUPS_REQUEST";
export const DELETE_INDIVIDUAL_PLAYER_GROUPS_REQUEST =
  "DELETE_INDIVIDUAL_PLAYER_GROUPS_REQUEST";
export const DELETE_INDIVIDUAL_PLAYER_GROUPS_FAILURE =
  "DELETE_INDIVIDUAL_PLAYER_GROUPS_FAILURE";
export const INDIVIDUAL_PLAYER_GROUP_LIST_REQUEST =
  "INDIVIDUAL_PLAYER_GROUP_LIST_REQUEST";
export const INDIVIDUAL_PLAYER_GROUP_LIST_SUCCESS =
  "INDIVIDUAL_PLAYER_GROUP_LIST_SUCCESS";
export const INDIVIDUAL_PLAYER_GROUP_LIST_FAILURE =
  "INDIVIDUAL_PLAYER_GROUP_LIST_FAILURE";
export const SAVE_INDIVIDUAL_PLAYER_GROUP_REQUEST =
  "SAVE_INDIVIDUAL_PLAYER_GROUP_REQUEST";
export const SAVE_INDIVIDUAL_PLAYER_GROUP_FAILURE =
  "SAVE_INDIVIDUAL_PLAYER_GROUP_FAILURE";
export const PLAYER_OVERVIEW_SETTINGS_REQUEST =
  "PLAYER_OVERVIEW_SETTINGS_REQUEST";
export const PLAYER_OVERVIEW_SETTINGS_SUCCESS =
  "PLAYER_OVERVIEW_SETTINGS_SUCCESS";
export const PLAYER_OVERVIEW_SETTINGS_FAILURE =
  "PLAYER_OVERVIEW_SETTINGS_FAILURE";
export const PLAYER_DETAIL_REQUEST = "PLAYER_DETAIL_REQUEST";
export const PLAYER_DETAIL_SUCCESS = "PLAYER_DETAIL_SUCCESS";
export const PLAYER_DETAIL_FAILURE = "PLAYER_DETAIL_FAILURE";
export const PLAYER_PAYMENT_REQUEST = "PLAYER_PAYMENT_REQUEST";
export const PLAYER_PAYMENT_CLEANUP = "PLAYER_PAYMENT_CLEANUP";
export const PLAYER_PAYMENT_SUCCESS = "PLAYER_PAYMENT_SUCCESS";
export const PLAYER_PAYMENT_FAILURE = "PLAYER_PAYMENT_FAILURE";
export const ACCOUNT_DETAILS_REQUEST = "ACCOUNT_DETAILS_REQUEST";
export const ACCOUNT_DETAILS_SUCCESS = "ACCOUNT_DETAILS_SUCCESS";
export const ACCOUNT_DETAILS_FAILURE = "ACCOUNT_DETAILS_FAILURE";

export const ACCOUNT_TRANSACTION_SERACH_CRETERIA_REQUEST =
  "ACCOUNT_TRANSACTION_SERACH_CRETERIA_REQUEST";
export const ACCOUNT_TRANSACTION_SERACH_CRETERIA_SUCCESS =
  "ACCOUNT_TRANSACTION_SERACH_CRETERIA_SUCCESS";
export const ACCOUNT_TRANSACTION_SERACH_CRETERIA_FAILURE =
  "ACCOUNT_TRANSACTION_SERACH_CRETERIA_FAILURE";
export const ACCOUNT_TRANSACTION_REQUEST = "ACCOUNT_TRANSACTION_REQUEST";
export const ACCOUNT_TRANSACTION_SUCCESS = "ACCOUNT_TRANSACTION_SUCCESS";
export const ACCOUNT_TRANSACTION_FAILURE = "ACCOUNT_TRANSACTION_FAILURE";
export const ACCOUNT_TRANSACTION_DETAIL_REQUEST =
  "ACCOUNT_TRANSACTION_DETAIL_REQUEST";
export const ACCOUNT_TRANSACTION_DETAIL_SUCCESS =
  "ACCOUNT_TRANSACTION_DETAIL_SUCCESS";
export const ACCOUNT_TRANSACTION_DETAIL_FAILURE =
  "ACCOUNT_TRANSACTION_DETAIL_FAILURE";
export const ACCOUNT_TRANSACTION_WRITE_OFF_REQUEST =
  "ACCOUNT_TRANSACTION_WRITE_OFF_REQUEST";
export const ACCOUNT_TRANSACTION_CONFIRM_CANCEL_RESERVE_REQUEST =
  "ACCOUNT_TRANSACTION_CONFIRM_CANCEL_RESERVE_REQUEST";

export const REWARD_TYPES_REQUEST = "REWARD_TYPES_REQUEST";
export const REWARD_TYPES_SUCCESS = "REWARD_TYPES_SUCCESS";
export const REWARD_TYPES_FAILURE = "REWARD_TYPES_FAILURE";

export const GROUP_NAME_REQUEST = "GROUP_NAME_REQUEST";
export const GROUP_NAME_SUCCESS = "GROUP_NAME_SUCCESS";
export const GROUP_NAME_FAILURE = "GROUP_NAME_FAILURE";

export const COMMUNICATIONS_REQUEST = "COMMUNICATIONS_REQUEST";
export const COMMUNICATIONS_SUCCESS = "COMMUNICATIONS_SUCCESS";
export const COMMUNICATIONS_FAILURE = "COMMUNICATIONS_FAILURE";

export const INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_REQUEST =
  "INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_REQUEST";
export const INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_SUCCESS =
  "INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_SUCCESS";
export const INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_FAILURE =
  "INDIVIDUAL_PLAYER_DIRECT_NOTIFICATIONS_HISTORY_FAILURE";
export const INDIVIDUAL_PLAYER_RESEND_DIRECT_NOTIFICATION_REQUEST =
  "INDIVIDUAL_PLAYER_RESEND_DIRECT_NOTIFICATION_REQUEST";
export const INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_REQUEST =
  "INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_REQUEST";
export const INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_SUCCESS =
  "INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_SUCCESS";
export const INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_FAILURE =
  "INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_DETAIL_FAILURE";
export const INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_REQUEST =
  "INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_REQUEST";
export const INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_SUCCESS =
  "INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_SUCCESS";
export const INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_FAILURE =
  "INDIVIDUAL_PLAYER_DIRECT_NOTIFICATION_ATTACHMENT_FAILURE";
export const CHANNELS_REQUEST = "CHANNELS_REQUEST";
export const CHANNELS_SUCCESS = "CHANNELS_SUCCESS";
export const CHANNELS_FAILURE = "CHANNELS_FAILURE";
export const TEMPLATE_VARIABLES_REQUEST = "TEMPLATE_VARIABLES_REQUEST";
export const TEMPLATE_VARIABLES_SUCCESS = "TEMPLATE_VARIABLES_SUCCESS";
export const TEMPLATE_VARIABLES_FAILURE = "TEMPLATE_VARIABLES_FAILURE";
export const TEMPLATES_REQUEST = "TEMPLATES_REQUEST";
export const TEMPLATES_SUCCESS = "TEMPLATES_SUCCESS";
export const TEMPLATES_FAILURE = "TEMPLATES_FAILURE";

export const SEND_DIRECT_NOTIFICATION_REQUEST =
  "SEND_DIRECT_NOTIFICATION_REQUEST";
export const SEND_DIRECT_NOTIFICATION_SUCCESS =
  "SEND_DIRECT_NOTIFICATION_SUCCESS";
export const SEND_DIRECT_NOTIFICATION_FAILURE =
  "SEND_DIRECT_NOTIFICATION_FAILURE";

export const COMMUNICATION_HISTORY_REQUEST = "COMMUNICATION_HISTORY_REQUEST";
export const COMMUNICATION_HISTORY_SUCCESS = "COMMUNICATION_HISTORY_SUCCESS";
export const COMMUNICATION_HISTORY_FAILURE = "COMMUNICATION_HISTORY_FAILURE";
export const COMMUNICATION_HISTORY_DETAILS_REQUEST =
  "COMMUNICATION_HISTORY_DETAILS_REQUEST";
export const COMMUNICATION_HISTORY_DETAILS_SUCCESS =
  "COMMUNICATION_HISTORY_DETAILS_SUCCESS";
export const COMMUNICATION_HISTORY_DETAILS_FAILURE =
  "COMMUNICATION_HISTORY_DETAILS_FAILURE";

export const PLAYER_NOTES_REQUEST = "PLAYER_NOTES_REQUEST";
export const PLAYER_NOTES_SUCCESS = "PLAYER_NOTES_SUCCESS";
export const PLAYER_NOTES_FAILURE = "PLAYER_NOTES_FAILURE";

export const PLAYER_NOTES_PRIORITIES_REQUEST =
  "PLAYER_NOTES_PRIORITIES_REQUEST";
export const PLAYER_NOTES_PRIORITIES_SUCCESS =
  "PLAYER_NOTES_PRIORITIES_SUCCESS";
export const PLAYER_NOTES_PRIORITIES_FAILURE =
  "PLAYER_NOTES_PRIORITIES_FAILURE";

export const PLAYER_NOTES_TYPES_REQUEST = "PLAYER_NOTES_TYPES_REQUEST";
export const PLAYER_NOTES_TYPES_SUCCESS = "PLAYER_NOTES_TYPES_SUCCESS";
export const PLAYER_NOTES_TYPES_FAILURE = "PLAYER_NOTES_TYPES_FAILURE";

export const PLAYER_NOTE_REQUEST = "PLAYER_NOTE_REQUEST";
export const PLAYER_NOTE_SUCCESS = "PLAYER_NOTE_SUCCESS";
export const PLAYER_NOTE_FAILURE = "PLAYER_NOTE_FAILURE";

export const PLAYER_NOTE_DELETE_REQUEST = "PLAYER_NOTE_DELETE_REQUEST";
export const PLAYER_NOTE_DELETE_SUCCESS = "PLAYER_NOTE_DELETE_SUCCESS";
export const PLAYER_NOTE_DELETE_FAILURE = "PLAYER_NOTE_DELETE_FAILURE";

export const PLAYER_NOTE_SAVE_REQUEST = "PLAYER_NOTE_SAVE_REQUEST";
export const PLAYER_NOTE_SAVE_SUCCESS = "PLAYER_NOTE_SAVE_SUCCESS";
export const PLAYER_NOTE_SAVE_FAILURE = "PLAYER_NOTE_SAVE_FAILURE";

export const FINANCIAL_LIMITS_ASSOCIATED_PROFILES_REQUEST =
  "FINANCIAL_LIMITS_ASSOCIATED_PROFILES_REQUEST";
export const FINANCIAL_LIMITS_ASSOCIATED_PROFILES_SUCCESS =
  "FINANCIAL_LIMITS_ASSOCIATED_PROFILES_SUCCESS";
export const FINANCIAL_LIMITS_ASSOCIATED_PROFILES_FAILURE =
  "FINANCIAL_LIMITS_ASSOCIATED_PROFILES_FAILURE";
export const FINANCIAL_LIMITS_EDIT_ASSOCIATED_PROFILES_REQUEST =
  "FINANCIAL_LIMITS_EDIT_ASSOCIATED_PROFILES_REQUEST";
export const FINANCIAL_LIMITS_EDIT_ASSOCIATED_PROFILES_SUCCESS =
  "FINANCIAL_LIMITS_EDIT_ASSOCIATED_PROFILES_SUCCESS";
export const FINANCIAL_LIMITS_EDIT_ASSOCIATED_PROFILES_FAILURE =
  "FINANCIAL_LIMITS_EDIT_ASSOCIATED_PROFILES_FAILURE";
export const FINANCIAL_LIMITS_ENABLE_DISABLE_ASSOCIATED_PROFILES_REQUEST =
  "FINANCIAL_LIMITS_ENABLE_DISABLE_ASSOCIATED_PROFILES_REQUEST";
export const FINANCIAL_LIMITS_ENABLE_DISABLE_ASSOCIATED_PROFILES_SUCCESS =
  "FINANCIAL_LIMITS_ENABLE_DISABLE_ASSOCIATED_PROFILES_SUCCESS";
export const FINANCIAL_LIMITS_ENABLE_DISABLE_ASSOCIATED_PROFILES_FAILURE =
  "FINANCIAL_LIMITS_ENABLE_DISABLE_ASSOCIATED_PROFILES_FAILURE";
export const FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_REQUEST =
  "FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_REQUEST";
export const FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_SUCCESS =
  "FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_SUCCESS";
export const FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_FAILURE =
  "FINANCIAL_LIMITS_NOT_ASSOCIATED_PROFILES_FAILURE";
export const FINANCIAL_LIMITS_SAVE_CONFIGURATION_PROFILES_REQUEST =
  "FINANCIAL_LIMITS_SAVE_CONFIGURATION_PROFILES_REQUEST";
export const FINANCIAL_LIMITS_APPROVE_REJECT_PROFILES_REQUEST =
  "FINANCIAL_LIMITS_APPROVE_REJECT_PROFILES_REQUEST";

export const FINANCIAL_LIMIT_HISTORY_REQUEST =
  "FINANCIAL_LIMIT_HISTORY_REQUEST";
export const FINANCIAL_LIMIT_HISTORY_SUCCESS =
  "FINANCIAL_LIMIT_HISTORY_SUCCESS";
export const FINANCIAL_LIMIT_HISTORY_FAILURE =
  "FINANCIAL_LIMIT_HISTORY_FAILURE";

export const GO_TO_ERROR_PAGE = "GO_TO_ERROR_PAGE";
export const NETWORK_BANS_REQUEST = "NETWORK_BANS_REQUEST";
export const NETWORK_BANS_SUCCESS = "NETWORK_BANS_SUCCESS";
export const NETWORK_BANS_FAILURE = "NETWORK_BANS_FAILURE";

export const DELETE_NETWORK_BANS_REQUEST = "DELETE_NETWORK_BANS_REQUEST";
export const DELETE_NETWORK_BANS_FAILURE = "DELETE_NETWORK_BANS_FAILURE";

export const PLATFORMS_REQUEST = "PLATFORMS_REQUEST";
export const PLATFORMS_SUCCESS = "PLATFORMS_SUCCESS";
export const PLATFORMS_FAILURE = "PLATFORMS_FAILURE";

export const GAME_CATEGORIES_REQUEST = "GAME_CATEGORIES_REQUEST";
export const GAME_CATEGORIES_SUCCESS = "GAME_CATEGORIES_SUCCESS";
export const GAME_CATEGORIES_FAILURE = "GAME_CATEGORIES_FAILURE";

export const GAMES_REQUEST = "GAMES_REQUEST";
export const GAMES_SUCCESS = "GAMES_SUCCESS";
export const GAMES_FAILURE = "GAMES_FAILURE";

export const ADD_BAN_REQUEST = "ADD_BAN_REQUEST";
export const ADD_BAN_FAILURE = "ADD_BAN_FAILURE";

export const PLAYER_FRIENDS_REQUEST = "PLAYER_FRIENDS_REQUEST";
export const PLAYER_FRIENDS_SUCCESS = "PLAYER_FRIENDS_SUCCESS";
export const PLAYER_FRIENDS_FAILURE = "PLAYER_FRIENDS_FAILURE";

export const KYC_STATUS_REQUEST = "KYC_STATUS_REQUEST";
export const KYC_STATUS_SUCCESS = "KYC_STATUS_SUCCESS";
export const KYC_STATUS_FAILURE = "KYC_STATUS_FAILURE";

export const KYC_STATUS_HISTORY_REQUEST = "KYC_STATUS_HISTORY_REQUEST";
export const KYC_STATUS_HISTORY_SUCCESS = "KYC_STATUS_HISTORY_SUCCESS";
export const KYC_STATUS_HISTORY_FAILURE = "KYC_STATUS_HISTORY_FAILURE";

export const KYC_STATUS_REQUEST_REQUEST = "KYC_STATUS_REQUEST_REQUEST";
export const KYC_STATUS_REQUEST_SUCCESS = "KYC_STATUS_REQUEST_SUCCESS";
export const KYC_STATUS_REQUEST_FAILURE = "KYC_STATUS_REQUEST_FAILURE";

export const KYC_EDIT_NOTE_REQUEST = "KYC_EDIT_NOTE_REQUEST";
export const KYC_EDIT_NOTE_SUCCESS = "KYC_EDIT_NOTE_SUCCESS";
export const KYC_EDIT_NOTE_FAILURE = "KYC_EDIT_NOTE_FAILURE";

export const KYC_CHANGE_STATE_REQUEST = "KYC_CHANGE_STATO_REQUEST";
export const KYC_CHANGE_STATE_SUCCESS = "KYC_CHANGE_STATO_SUCCESS";
export const KYC_CHANGE_STATE_FAILURE = "KYC_CHANGE_STATO_FAILURE";

export const CURRENCIES_REQUEST = "CURRENCIES_REQUEST";
export const CURRENCIES_SUCCESS = "CURRENCIES_SUCCESS";
export const CURRENCIES_FAILURE = "CURRENCIES_FAILURE";
export const PAM_CURRENCIES_REQUEST = "PAM_CURRENCIES_REQUEST";
export const PAM_CURRENCIES_SUCCESS = "PAM_CURRENCIES_SUCCESS";
export const PAM_CURRENCIES_FAILURE = "PAM_CURRENCIES_FAILURE";

export const LINKED_ACCOUNTS_REQUEST = "LINKED_ACCOUNTS_REQUEST";
export const LINKED_ACCOUNTS_SUCCESS = "LINKED_ACCOUNTS_SUCCESS";
export const LINKED_ACCOUNTS_FAILURE = "LINKED_ACCOUNTS_FAILURE";

export const UNLINK_LINKED_ACCOUNT_REQUEST = "UNLINK_LINKED_ACCOUNT_REQUEST";
export const UNLINK_LINKED_ACCOUNT_SUCCESS = "UNLINK_LINKED_ACCOUNT_SUCCESS";
export const UNLINK_LINKED_ACCOUNT_FAILURE = "UNLINK_LINKED_ACCOUNT_FAILURE";

export const LINK_LINKED_ACCOUNT_REQUEST = "LINK_LINKED_ACCOUNT_REQUEST";
export const LINK_LINKED_ACCOUNT_SUCCESS = "LINK_LINKED_ACCOUNT_SUCCESS";
export const LINK_LINKED_ACCOUNT_FAILURE = "LINK_LINKED_ACCOUNT_FAILURE";

export const PLAYERS_ID_LIST_REQUEST = "PLAYERS_ID_LIST_REQUEST";
export const PLAYERS_ID_LIST_SUCCESS = "PLAYERS_ID_LIST_SUCCESS";
export const PLAYERS_ID_LIST_FAILURE = "PLAYERS_ID_LIST_FAILURE";

export const OPERATIONS_REQUEST = "OPERATIONS_REQUEST";
export const OPERATIONS_SUCCESS = "OPERATIONS_SUCCESS";
export const OPERATIONS_FAILURE = "OPERATIONS_FAILURE";

export const CURRENCIES_FULL_DATA_REQUEST = "CURRENCIES_FULL_DATA_REQUEST";
export const CURRENCIES_FULL_DATA_SUCCESS = "CURRENCIES_FULL_DATA_SUCCESS";
export const CURRENCIES_FULL_DATA_FAILURE = "CURRENCIES_FULL_DATA_FAILURE";

export const BULK_TEMPLATE_REQUEST = "BULK_TEMPLATE_REQUEST";
export const BULK_TEMPLATE_SUCCESS = "BULK_TEMPLATE_SUCCESS";
export const BULK_TEMPLATE_FAILURE = "BULK_TEMPLATE_FAILURE";

export const GAME_DETAIL_REQUEST = "GAME_DETAIL_REQUEST";
export const GAME_DETAIL_SUCCESS = "GAME_DETAIL_SUCCESS";
export const GAME_DETAIL_FAILURE = "GAME_DETAIL_FAILURE";

export const INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_REQUEST =
  "INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_REQUEST";
export const INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_SUCCESS =
  "INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_SUCCESS";
export const INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_FAILURE =
  "INDIVIDUAL_PLAYER_KEY_ACCESS_LIST_FAILURE";
export const INDIVIDUAL_PLAYER_KEY_ACCESS_RESET_REQUEST =
  "INDIVIDUAL_PLAYER_KEY_ACCESS_RESET_REQUEST";
export const INDIVIDUAL_PLAYER_KEY_ACCESS_LOCK_UNLOCK_REQUEST =
  "INDIVIDUAL_PLAYER_KEY_ACCESS_LOCK_UNLOCK_REQUEST";
export const INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_REQUEST =
  "INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_REQUEST";
export const INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_SUCCESS =
  "INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_SUCCESS";
export const INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_FAILURE =
  "INDIVIDUAL_PLAYER_KEY_ACCESS_EDIT_FAILURE";
export const INDIVIDUAL_PLAYER_KEY_ACCESS_RESET_EDIT_MODAL =
  "INDIVIDUAL_PLAYER_KEY_ACCESS_RESET_EDIT_MODAL";
export const INDIVIDUAL_PLAYER_KEY_ACCESS_LOCK_SUCCESS =
  "INDIVIDUAL_PLAYER_KEY_ACCESS_LOCK_SUCCESS";

export const EXTERNAL_LINKED_ACCOUNTS_REQUEST =
  "EXTERNAL_LINKED_ACCOUNTS_REQUEST";
export const EXTERNAL_LINKED_ACCOUNTS_SUCCESS =
  "EXTERNAL_LINKED_ACCOUNTS_SUCCESS";
export const EXTERNAL_LINKED_ACCOUNTS_FAILURE =
  "EXTERNAL_LINKED_ACCOUNTS_FAILURE";

export const UNLINK_EXTERNAL_LINKED_ACCOUNT_REQUEST =
  "UNLINK_EXTERNAL_LINKED_ACCOUNT_REQUEST";
export const UNLINK_EXTERNAL_LINKED_ACCOUNT_SUCCESS =
  "UNLINK_EXTERNAL_LINKED_ACCOUNT_SUCCESS";
export const UNLINK_EXTERNAL_LINKED_ACCOUNT_FAILURE =
  "UNLINK_EXTERNAL_LINKED_ACCOUNT_FAILURE";

export const UPDATE_EXTERNAL_LINKED_ACCOUNT_REQUEST =
  "UPDATE_EXTERNAL_LINKED_ACCOUNT_REQUEST";
export const UPDATE_EXTERNAL_LINKED_ACCOUNT_SUCCESS =
  "UPDATE_EXTERNAL_LINKED_ACCOUNT_SUCCESS";
export const UPDATE_EXTERNAL_LINKED_ACCOUNT_FAILURE =
  "UPDATE_EXTERNAL_LINKED_ACCOUNT_FAILURE";

export const KEY_ACCESS_GAME_ACCOUNT = "GameAccount";
export const KEY_ACCESS_USERNAME = "Username";

export const INDIVIDUAL_PLAYER_CONSENTS_LIST_REQUEST =
  "INDIVIDUAL_PLAYER_CONSENTS_LIST_REQUEST";
export const INDIVIDUAL_PLAYER_CONSENTS_LIST_SUCCESS =
  "INDIVIDUAL_PLAYER_CONSENTS_LIST_SUCCESS";
export const INDIVIDUAL_PLAYER_CONSENTS_LIST_FAILURE =
  "INDIVIDUAL_PLAYER_CONSENTS_LIST_FAILURE";
export const INDIVIDUAL_PLAYER_CONSENTS_EDIT_REQUEST =
  "INDIVIDUAL_PLAYER_CONSENTS_EDIT_REQUEST";
export const INDIVIDUAL_PLAYER_CONSENTS_EDIT_SUCCESS =
  "INDIVIDUAL_PLAYER_CONSENTS_EDIT_SUCCESS";
export const INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_REQUEST =
  "INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_REQUEST";
export const INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_SUCCESS =
  "INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_SUCCESS";
export const INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_FAILURE =
  "INDIVIDUAL_PLAYER_CONSENTS_HISTORY_LIST_FAILURE";

export const SAVE_REWARD_REQUEST = "SAVE_REWARD_REQUEST";
export const CLONE_REWARD_SUCCESS = "CLONE_REWARD_SUCCESS";
export const CHECK_LIMIT = "CHECK_LIMIT";
export const ADD_REWARD_REQUEST = "ADD_REWARD_REQUEST";
export const LOYALTY_LIMITS_REQUEST = "LOYALTY_LIMITS_REQUEST";
export const LOYALTY_LIMITS_SUCCESS = "LOYALTY_LIMITS_SUCCESS";
export const LOYALTY_LIMITS_FAILURE = "LOYALTY_LIMITS_FAILURE";

export const SIMPLE_PLATFORMS_REQUEST = "SIMPLE_PLATFORMS_REQUEST";
export const SIMPLE_PLATFORMS_SUCCESS = "SIMPLE_PLATFORMS_SUCCESS";
export const SIMPLE_PLATFORMS_FAILURE = "SIMPLE_PLATFORMS_FAILURE";

export const SELF_EXCLUSION_REQUEST = "SELF_EXCLUSION_REQUEST";
export const SELF_EXCLUSION_SUCCESS = "SELF_EXCLUSION_SUCCESS";
export const SELF_EXCLUSION_FAILURE = "SELF_EXCLUSION_FAILURE";
export const SELF_EXCLUSION_HISTORY_REQUEST = "SELF_EXCLUSION_HISTORY_REQUEST";
export const SELF_EXCLUSION_HISTORY_SUCCESS = "SELF_EXCLUSION_HISTORY_SUCCESS";
export const SELF_EXCLUSION_HISTORY_FAILURE = "SELF_EXCLUSION_HISTORY_FAILURE";

export const UPDATE_SELF_EXCLUSION_REQUEST = "UPDATE_SELF_EXCLUSION_REQUEST";
export const UPDATE_SELF_EXCLUSION_SUCCESS = "UPDATE_SELF_EXCLUSION_SUCCESS";
export const UPDATE_SELF_EXCLUSION_FAILURE = "UPDATE_SELF_EXCLUSION_FAILURE";

export const NETWORK_LIMITS_ASSOCIATED_PROFILES_REQUEST =
  "NETWORK_LIMITS_ASSOCIATED_PROFILES_REQUEST";
export const NETWORK_LIMITS_ASSOCIATED_PROFILES_SUCCESS =
  "NETWORK_LIMITS_ASSOCIATED_PROFILES_SUCCESS";
export const NETWORK_LIMITS_ASSOCIATED_PROFILES_FAILURE =
  "NETWORK_LIMITS_ASSOCIATED_PROFILES_FAILURE";
export const NETWORK_LIMITS_EDIT_PROFILE_REQUEST =
  "NETWORK_LIMITS_EDIT_PROFILE_REQUEST";

export const GAME_WAGER_LIMITS_ASSOCIATED_PROFILES_REQUEST =
  "GAME_WAGER_LIMITS_ASSOCIATED_PROFILES_REQUEST";
export const GAME_WAGER_LIMITS_ASSOCIATED_PROFILES_SUCCESS =
  "GAME_WAGER_LIMITS_ASSOCIATED_PROFILES_SUCCESS";
export const GAME_WAGER_LIMITS_ASSOCIATED_PROFILES_FAILURE =
  "GAME_WAGER_LIMITS_ASSOCIATED_PROFILES_FAILURE";
export const GAME_WAGER_LIMITS_EDIT_PROFILE_REQUEST =
  "GAME_WAGER_LIMITS_EDIT_PROFILE_REQUEST";

export const SESSION_LIMITS_REQUEST = "SESSION_LIMITS_REQUEST";
export const SESSION_LIMITS_SUCCESS = "SESSION_LIMITS_SUCCESS";
export const SESSION_LIMITS_FAILURE = "SESSION_LIMITS_FAILURE";

export const SESSION_LIMITS_EDIT_REQUEST = "SESSION_LIMITS_EDIT_REQUEST";
export const SESSION_LIMITS_EDIT_SUCCESS = "SESSION_LIMITS_EDIT_SUCCESS";
export const SESSION_LIMITS_EDIT_FAILURE = "SESSION_LIMITS_EDIT_FAILURE";

export const SESSION_LIMITS_RESET_REQUEST = "SESSION_LIMITS_RESET_REQUEST";
export const SESSION_LIMITS_RESET_SUCCESS = "SESSION_LIMITS_RESET_SUCCESS";
export const SESSION_LIMITS_RESET_FAILURE = "SESSION_LIMITS_RESET_FAILURE";

export const PLAYER_SESSION_INFO_REQUEST = "PLAYER_SESSION_INFO_REQUEST";
export const PLAYER_SESSION_INFO_SUCCESS = "PLAYER_SESSION_INFO_SUCCESS";
export const PLAYER_SESSION_INFO_FAILURE = "PLAYER_SESSION_INFO_FAILURE";

export const TOKEN_INVALIDATION_REQUEST = "TOKEN_INVALIDATION_REQUEST";
export const TOKEN_INVALIDATION_SUCCESS = "TOKEN_INVALIDATION_SUCCESS";
export const TOKEN_INVALIDATION_FAILURE = "TOKEN_INVALIDATION_FAILURE";

export const BONUS_ABUSER_HISTORY_REQUEST = "BONUS_ABUSER_HISTORY_REQUEST";
export const BONUS_ABUSER_HISTORY_SUCCESS = "BONUS_ABUSER_HISTORY_SUCCESS";
export const BONUS_ABUSER_HISTORY_FAILURE = "BONUS_ABUSER_HISTORY_FAILURE";
export const BONUS_ABUSER_MANAGEMENT_REQUEST =
  "BONUS_ABUSER_MANAGEMENT_REQUEST";
export const BONUS_ABUSER_MANAGEMENT_SUCCESS =
  "BONUS_ABUSER_MANAGEMENT_SUCCESS";

export const LOYALTY_TRANSACTION_SEARCH_CRITERIA_REQUEST =
  "LOYALTY_TRANSACTION_SEARCH_CRITERIA_REQUEST";
export const LOYALTY_TRANSACTION_SEARCH_CRITERIA_SUCCESS =
  "LOYALTY_TRANSACTION_SEARCH_CRITERIA_SUCCESS";
export const LOYALTY_TRANSACTION_SEARCH_CRITERIA_FAILURE =
  "LOYALTY_TRANSACTION_SEARCH_CRITERIA_FAILURE";
export const LOYALTY_TRANSACTION_LIST_REQUEST =
  "LOYALTY_TRANSACTION_LIST_REQUEST";
export const LOYALTY_TRANSACTION_LIST_SUCCESS =
  "LOYALTY_TRANSACTION_LIST_SUCCESS";
export const LOYALTY_TRANSACTION_LIST_FAILURE =
  "LOYALTY_TRANSACTION_LIST_FAILURE";

export const VALIDITY_DATE_TYPE_REQUEST = "VALIDITY_DATE_TYPE_REQUEST";
export const VALIDITY_DATE_TYPE_SUCCESS = "VALIDITY_DATE_TYPE_SUCCESS";
export const VALIDITY_DATE_TYPE_FAILURE = "VALIDITY_DATE_TYPE_FAILURE";

export const BONUS_TYPES_REQUEST = "BONUS_TYPES_REQUEST";
export const BONUS_TYPES_SUCCESS = "BONUS_TYPES_SUCCESS";
export const BONUS_TYPES_FAILURE = "BONUS_TYPES_FAILURE";

export const DEVICES_REQUEST = "DEVICES_REQUEST";
export const DEVICES_SUCCESS = "DEVICES_SUCCESS";
export const DEVICES_FAILURE = "DEVICES_FAILURE";

export const THRESHOLD_TYPES_REQUEST = "THRESHOLD_TYPES_REQUEST";
export const THRESHOLD_TYPES_SUCCESS = "THRESHOLD_TYPES_SUCCESS";
export const THRESHOLD_TYPES_FAILURE = "THRESHOLD_TYPES_FAILURE";

export const USER_PREFERENCES_RETRIEVE_REQUEST =
  "USER_PREFERENCES_RETRIEVE_REQUEST";
export const USER_PREFERENCES_RETRIEVE_SUCCESS =
  "USER_PREFERENCES_RETRIEVE_SUCCESS";
export const USER_PREFERENCES_RETRIEVE_FAILURE =
  "USER_PREFERENCES_RETRIEVE_FAILURE";

export const USER_CUSTOM_SETTING_UPDATE_REQUEST =
  "USER_CUSTOM_SETTING_UPDATE_REQUEST";
export const USER_CUSTOM_SETTING_UPDATE_SUCCESS =
  "USER_CUSTOM_SETTING_UPDATE_SUCCESS";
export const USER_CUSTOM_SETTING_UPDATE_FAILURE =
  "USER_CUSTOM_SETTING_UPDATE_FAILURE";

export const LONG_RUNNING_TASK_REQUEST = "LONG_RUNNING_TASK_REQUEST";
export const LONG_RUNNING_TASK_SUCCESS = "LONG_RUNNING_TASK_SUCCESS";
export const LONG_RUNNING_TASK_FAILURE = "LONG_RUNNING_TASK_FAILURE";

export const LONG_RUNNING_ADD_TASK_REQUEST = "LONG_RUNNING_ADD_TASK_REQUEST";
export const LONG_RUNNING_REMOVE_TASK_REQUEST =
  "LONG_RUNNING_REMOVE_TASK_REQUEST";

export const LONG_RUNNING_RETRY_TASK_REQUEST =
  "LONG_RUNNING_RETRY_TASK_REQUEST";
export const LONG_RUNNING_RETRY_TASK_SUCCESS =
  "LONG_RUNNING_RETRY_TASK_SUCCESS";
export const LONG_RUNNING_RETRY_TASK_FAILURE =
  "LONG_RUNNING_RETRY_TASK_FAILURE";

export const LONG_RUNNING_TRIGGER_TASK_REQUEST =
  "LONG_RUNNING_TRIGGER_TASK_REQUEST";
export const LONG_RUNNING_TRIGGER_TASK_FAILURE =
  "LONG_RUNNING_TRIGGER_TASK_FAILURE";

export const BLOCKLIST_ADD_REMOVE_REQUEST = "BLOCKLIST_ADD_REMOVE_REQUEST";
export const BLOCKLIST_ADD_REMOVE_SUCCESS = "BLOCKLIST_ADD_REMOVE_SUCCESS";
export const BLOCKLIST_ADD_REMOVE_FAILURE = "BLOCKLIST_ADD_REMOVE_FAILURE";
export const PLAYERBLOCKLIST_HISTORY_REQUEST =
  "PLAYERBLOCKLIST_HISTORY_REQUEST";
export const PLAYERBLOCKLIST_HISTORY_SUCCESS =
  "PLAYERBLOCKLIST_HISTORY_SUCCESS";
export const PLAYERBLOCKLIST_HISTORY_FAILURE =
  "PLAYERBLOCKLIST_HISTORY_FAILURE";
export const CHECK_PLAYERBLOCKLIST_REQUEST = "CHECK_PLAYERBLOCKLIST_REQUEST";
export const CHECK_PLAYERBLOCKLIST_SUCCESS = "CHECK_PLAYERBLOCKLIST_SUCCESS";
export const CHECK_PLAYERBLOCKLIST_FAILURE = "CHECK_PLAYERBLOCKLIST_FAILURE";

export const TASK_TYPES_REQUEST = "TASK_TYPES_REQUEST";
export const TASK_TYPES_SUCCESS = "TASK_TYPES_SUCCESS";
export const TASK_TYPES_FAILURE = "TASK_TYPES_FAILURE";

export const ABUSER_MODAL_OPEN = "ABUSER_MODAL_OPEN";
export const ABUSER_MODAL_CLOSE = "ABUSER_MODAL_CLOSE";

export const BONUS_BAGS_REQUEST = "BONUS_BAGS_REQUEST";
export const BONUS_BAGS_SUCCESS = "BONUS_BAGS_SUCCESS";
export const BONUS_BAGS_FAILURE = "BONUS_BAGS_FAILURE";

export const BONUS_BAG_REQUEST = "BONUS_BAG_REQUEST";
export const BONUS_BAG_SUCCESS = "BONUS_BAG_SUCCESS";
export const BONUS_BAG_FAILURE = "BONUS_BAG_FAILURE";

export const BONUS_BAG_UNFREEZE_REQUEST = "BONUS_BAG_UNFREEZE_REQUEST";
export const BONUS_BAG_UNFREEZE_SUCCESS = "BONUS_BAG_UNFREEZE_SUCCESS";
export const BONUS_BAG_UNFREEZE_FAILURE = "BONUS_BAG_UNFREEZE_FAILURE";

export const BONUS_BAG_ACCEPT_REQUEST = "BONUS_BAG_ACCEPT_REQUEST";
export const BONUS_BAG_ACCEPT_SUCCESS = "BONUS_BAG_ACCEPT_SUCCESS";
export const BONUS_BAG_ACCEPT_FAILURE = "BONUS_BAG_ACCEPT_FAILURE";

export const BONUS_BAG_CONVERT_REQUEST = "BONUS_BAG_CONVERT_REQUEST";
export const BONUS_BAG_CONVERT_SUCCESS = "BONUS_BAG_CONVERT_SUCCESS";
export const BONUS_BAG_CONVERT_FAILURE = "BONUS_BAG_CONVERT_FAILURE";
export const REMOVE_BONUS_BAG_REQUEST = "REMOVE_BONUS_BAG_REQUEST";
export const REMOVE_BONUS_BAG_FAILURE = "REMOVE_BONUS_BAG_FAILURE";

export const BONUS_BAG_TRANSACTIONS_REQUEST = "BONUS_BAG_TRANSACTIONS_REQUEST";
export const BONUS_BAG_TRANSACTIONS_SUCCESS = "BONUS_BAG_TRANSACTIONS_SUCCESS";
export const BONUS_BAG_TRANSACTIONS_FAILURE = "BONUS_BAG_TRANSACTIONS_FAILURE";

export const LOYALTY_DETAILS_REQUEST = "LOYALTY_DETAILS_REQUEST";
export const LOYALTY_DETAILS_SUCCESS = "LOYALTY_DETAILS_SUCCESS";
export const LOYALTY_DETAILS_FAILURE = "LOYALTY_DETAILS_FAILURE";

export const LOYALTY_LEVEL_CHANGE_REQUEST = "LOYALTY_LEVEL_CHANGE_REQUEST";
export const LOYALTY_LEVEL_CHANGE_SUCCESS = "LOYALTY_LEVEL_CHANGE_SUCCESS";
export const LOYALTY_LEVEL_CHANGE_FAILURE = "LOYALTY_LEVEL_CHANGE_FAILURE";

export const LOYALTY_CONVERT_REQUEST = "LOYALTY_CONVERT_REQUEST";
export const LOYALTY_CONVERT_SUCCESS = "LOYALTY_CONVERT_SUCCESS";
export const LOYALTY_CONVERT_FAILURE = "LOYALTY_CONVERT_FAILURE";

export const FREE_SPIN_SEARCH_CRITERIA_REQUEST =
  "FREE_SPIN_SEARCH_CRITERIA_REQUEST";
export const FREE_SPIN_SEARCH_CRITERIA_SUCCESS =
  "FREE_SPIN_SEARCH_CRITERIA_SUCCESS";
export const FREE_SPIN_SEARCH_CRITERIA_FAILURE =
  "FREE_SPIN_SEARCH_CRITERIA_FAILURE";
export const FREE_SPIN_LIST_REQUEST = "FREE_SPIN_LIST_REQUEST";
export const FREE_SPIN_LIST_SUCCESS = "FREE_SPIN_LIST_SUCCESS";
export const FREE_SPIN_LIST_FAILURE = "FREE_SPIN_LIST_FAILURE";
export const FREE_SPIN_DELETE_REQUEST = "FREE_SPIN_DELETE_REQUEST";
export const FREE_SPIN_DELETE_SUCCESS = "FREE_SPIN_DELETE_SUCCESS";
export const FREE_SPIN_DELETE_FAILURE = "FREE_SPIN_DELETE_FAILURE";

export const TICKETS_SEARCH_CRITERIA_REQUEST =
  "TICKETS_SEARCH_CRITERIA_REQUEST";
export const TICKETS_SEARCH_CRITERIA_SUCCESS =
  "TICKETS_SEARCH_CRITERIA_SUCCESS";
export const TICKETS_SEARCH_CRITERIA_FAILURE =
  "TICKETS_SEARCH_CRITERIA_FAILURE";
export const TICKETS_LIST_REQUEST = "TICKETS_LIST_REQUEST";
export const TICKETS_LIST_SUCCESS = "TICKETS_LIST_SUCCESS";
export const TICKETS_LIST_FAILURE = "TICKETS_LIST_FAILURE";
export const TICKETS_DELETE_REQUEST = "TICKETS_DELETE_REQUEST";
export const TICKETS_DELETE_SUCCESS = "TICKETS_DELETE_SUCCESS";
export const TICKETS_DELETE_FAILURE = "TICKETS_DELETE_FAILURE";

export const PROGRAM_PLATFORMS_REQUEST = "PROGRAM_PLATFORMS_REQUEST";
export const PROGRAM_PLATFORMS_SUCCESS = "PROGRAM_PLATFORMS_SUCCESS";
export const PROGRAM_PLATFORMS_FAILURE = "PROGRAM_PLATFORMS_FAILURE";

export const GAMES_OVERVIEW_REQUEST = "GAMES_OVERVIEW_REQUEST";
export const GAMES_OVERVIEW_SUCCESS = "GAMES_OVERVIEW_SUCCESS";
export const GAMES_OVERVIEW_FAILURE = "GAMES_OVERVIEW_FAILURE";

export const ORGANIZATION_REQUEST = "ORGANIZATION_REQUEST";
export const ORGANIZATION_SUCCESS = "ORGANIZATION_SUCCESS";
export const ORGANIZATION_FAILURE = "ORGANIZATION_FAILURE";

export const PLAYER_LEVELS_REQUEST = "PLAYER_LEVELS_REQUEST";
export const PLAYER_LEVELS_SUCCESS = "PLAYER_LEVELS_SUCCESS";
export const PLAYER_LEVELS_FAILURE = "PLAYER_LEVELS_FAILURE";

export const PLAYER_LEVEL_FIELDS_REQUEST = "PLAYER_LEVEL_FIELDS_REQUEST";
export const PLAYER_LEVEL_FIELDS_SUCCESS = "PLAYER_LEVEL_FIELDS_SUCCESS";
export const PLAYERS_LEVEL_FIELDS_SUCCESS = "PLAYERS_LEVEL_FIELDS_SUCCESS";
export const PLAYER_LEVEL_FIELDS_FAILURE = "PLAYER_LEVEL_FIELDS_FAILURE";

export const PLAYER_LANGUAGES_REQUEST = "PLAYER_LANGUAGES_REQUEST";
export const PLAYER_LANGUAGES_SUCCESS = "PLAYER_LANGUAGES_SUCCESS";

export const COUNTRIES_REQUEST = "COUNTRIES_REQUEST";
export const COUNTRIES_SUCCESS = "COUNTRIES_SUCCESS";

export const CONSENTS_REQUEST = "CONSENTS_REQUEST";
export const CONSENTS_SUCCESS = "CONSENTS_SUCCESS";

export const PLAYER_SAVE_REQUEST = "PLAYER_SAVE_REQUEST";
export const PLAYER_SAVE_SUCCESS = "PLAYER_SAVE_SUCCESS";
export const PLAYER_SAVE_FAILURE = "PLAYER_SAVE_FAILURE";

export const UPGRADE_PLAYER_SAVE_REQUEST = "UPGRADE_PLAYER_SAVE_REQUEST";
export const UPGRADE_PLAYER_SAVE_SUCCESS = "UPGRADE_PLAYER_SAVE_SUCCESS";
export const UPGRADE_PLAYER_SAVE_FAILURE = "UPGRADE_PLAYER_SAVE_FAILURE";

export const VOUCHER_TEMPLATES_REQUEST = "VOUCHER_TEMPLATES_REQUEST";
export const VOUCHER_TEMPLATES_SUCCESS = "VOUCHER_TEMPLATES_SUCCESS";
export const VOUCHER_TEMPLATES_FAILURE = "VOUCHER_TEMPLATES_FAILURE";

export const BONUS_BAG_TEMPLATES_REQUEST = "BONUS_BAG_TEMPLATES_REQUEST";
export const BONUS_BAG_TEMPLATES_SUCCESS = "BONUS_BAG_TEMPLATES_SUCCESS";
export const BONUS_BAG_TEMPLATES_FAILURE = "BONUS_BAG_TEMPLATES_FAILURE";

export const GAME_TRANSACTIONS_LIST_REQUEST = "GAME_TRANSACTIONS_LIST_REQUEST";
export const GAME_TRANSACTIONS_LIST_SUCCESS = "GAME_TRANSACTIONS_LIST_SUCCESS";
export const GAME_TRANSACTIONS_LIST_FAILURE = "GAME_TRANSACTIONS_LIST_FAILURE";

export const GAME_TRANSACTION_DETAIL_REQUEST =
  "GAME_TRANSACTION_DETAIL_REQUEST";
export const GAME_TRANSACTION_DETAIL_SUCCESS =
  "GAME_TRANSACTION_DETAIL_SUCCESS";
export const GAME_TRANSACTION_DETAIL_FAILURE =
  "GAME_TRANSACTION_DETAIL_FAILURE";

export const GAME_REPLAY_REQUEST = "GAME_REPLAY_REQUEST";
export const GAME_REPLAY_SUCCESS = "GAME_REPLAY_SUCCESS";
export const GAME_REPLAY_FAILURE = "GAME_REPLAY_FAILURE";
export const GAME_REPLAY_RESET = "GAME_REPLAY_RESET";

export const SUSPEND_GAME_ACCOUNT_REQUEST = "SUSPEND_GAME_ACCOUNT_REQUEST";
export const SUSPEND_GAME_ACCOUNT_SUCCESS = "SUSPEND_GAME_ACCOUNT_SUCCESS";
export const SUSPEND_GAME_ACCOUNT_FAILURE = "SUSPEND_GAME_ACCOUNT_FAILURE";

export const REACTIVATE_GAME_ACCOUNT_REQUEST =
  "REACTIVATE_GAME_ACCOUNT_REQUEST";
export const REACTIVATE_GAME_ACCOUNT_SUCCESS =
  "REACTIVATE_GAME_ACCOUNT_SUCCESS";
export const REACTIVATE_GAME_ACCOUNT_FAILURE =
  "REACTIVATE_GAME_ACCOUNT_FAILURE";

export const UPDATE_PLATFORM_NICKNAME_REQUEST =
  "UPDATE_PLATFORM_NICKNAME_REQUEST";
export const UPDATE_PLATFORM_NICKNAME_SUCCESS =
  "UPDATE_PLATFORM_NICKNAME_SUCCESS";
export const UPDATE_PLATFORM_NICKNAME_FAILURE =
  "UPDATE_PLATFORM_NICKNAME_FAILURE";
export const DELETE_PLATFORM_NICKNAME_REQUEST =
  "DELETE_PLATFORM_NICKNAME_REQUEST";
export const LOAD_PLATFORM_NICKNAMES_REQUEST =
  "LOAD_PLATFORM_NICKNAMES_REQUEST";
export const PLATFORM_NICKAMES_LIST_SUCCESS = "PLATFORM_NICKAMES_LIST_SUCCESS";
export const PLATFORM_NICKAMES_LIST_FAILURE = "PLATFORM_NICKAMES_LIST_FAILURE";
export const ADD_PLATFORM_NICKNAME_REQUEST = "ADD_PLATFORM_NICKNAME_REQUEST";
export const ADD_PLATFORM_NICKNAME_SUCCESS = "ADD_PLATFORM_NICKNAME_SUCCESS";
export const ADD_PLATFORM_NICKNAME_FAILURE = "ADD_PLATFORM_NICKNAME_FAILURE";
export const UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_REQUEST =
  "UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_REQUEST";
export const UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_SUCCESS =
  "UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_SUCCESS";
export const UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_FAILURE =
  "UPDATE_PLATFORM_NICKNAME_BY_PLATFORM_FAILURE";
export const START_ACCOUNT_CLOSURE_REQUEST = "START_ACCOUNT_CLOSURE_REQUEST";
export const START_ACCOUNT_CLOSURE_SUCCESS = "START_ACCOUNT_CLOSURE_SUCCESS";
export const START_ACCOUNT_CLOSURE_FAILURE = "START_ACCOUNT_CLOSURE_FAILURE";
export const CANCEL_ACCOUNT_CLOSURE_REQUEST = "CANCEL_ACCOUNT_CLOSURE_REQUEST";
export const CANCEL_ACCOUNT_CLOSURE_SUCCESS = "CANCEL_ACCOUNT_CLOSURE_SUCCESS";
export const CANCEL_ACCOUNT_CLOSURE_FAILURE = "CANCEL_ACCOUNT_CLOSURE_FAILURE";
export const BLOCKADE = {
  REQUEST: "BLOCKADE_REQUEST",
  FAILURE: "BLOCKADE_FAILURE",
  SUCCESS: "BLOCKADE_SUCCESS",
};
export const CONFIRM_ACCOUNT_CLOSURE_REQUEST =
  "CONFIRM_ACCOUNT_CLOSURE_REQUEST";
export const CONFIRM_ACCOUNT_CLOSURE_SUCCESS =
  "CONFIRM_ACCOUNT_CLOSURE_SUCCESS";
export const CONFIRM_ACCOUNT_CLOSURE_FAILURE =
  "CONFIRM_ACCOUNT_CLOSURE_FAILURE";
export const CONVERT_TEST_PLAYER_ACCOUNT_REQUEST =
  "CONVERT_TEST_PLAYER_ACCOUNT_REQUEST";
export const CONVERT_TEST_PLAYER_ACCOUNT_SUCCESS =
  "CONVERT_TEST_PLAYER_ACCOUNT_SUCCESS";
export const CONVERT_TEST_PLAYER_ACCOUNT_FAILURE =
  "CONVERT_TEST_PLAYER_ACCOUNT_FAILURE";
export const REASONS_REQUEST = "REASONS_REQUEST";
export const REASONS_SUCCESS = "REASONS_SUCCESS";
export const REASONS_FAILURE = "REASONS_FAILURE";
export const REASON_DELETE_REQUEST = "REASON_DELETE_REQUEST";
export const REASON_DELETE_SUCCESS = "REASON_DELETE_SUCCESS";
export const REASON_DELETE_FAILURE = "REASON_DELETE_FAILURE";

export const REASONS_BY_ACTION_REQUEST = "REASONS_BY_ACTION_REQUEST";
export const REASONS_BY_ACTION_SUCCESS = "REASONS_BY_ACTION_SUCCESS";
export const REASONS_BY_ACTION_FAILURE = "REASONS_BY_ACTION_FAILURE";

export const REASONS_SAVE_REQUEST = "REASONS_SAVE_REQUEST";
export const REASONS_SAVE_SUCCESS = "REASONS_SAVE_SUCESS";
export const REASONS_SAVE_FAILURE = "REASONS_SAVE_FAILURE";

export const REASONS_ACTIONS_REQUEST = "REASONS_ACTIONS_REQUEST";
export const REASONS_ACTIONS_SUCCESS = "REASONS_ACTIONS_SUCCESS";
export const REASONS_ACTIONS_FAILURE = "REASONS_ACTIONS_FAILURE";

export const LANGUAGES_SUCCESS = "LANGUAGES_SUCCESS";
export const LANGUAGES_FAILURE = "LANGUAGES_FAILURE";

export const DISABLE_ENABLE_CARD_REQUEST = "DISABLE_ENABLE_CARD_REQUEST";
export const DISABLE_ENABLE_CARD_SUCCESS = "DISABLE_ENABLE_CARD_SUCCESS";
export const DISABLE_ENABLE_CARD_FAILURE = "DISABLE_ENABLE_CARD_FAILURE";

export const CASH_OUT_REQUEST = "CASH_OUT_REQUEST";
export const CASH_OUT_SUCCESS = "CASH_OUT_SUCCESS";
export const CASH_OUT_FAILURE = "CASH_OUT_FAILURE";

export const APPROVE_REJECT_REQUEST = "APPROVE_REJECT_REQUEST";
export const APPROVE_REJECT_SUCCESS = "APPROVE_REJECT_SUCCESS";
export const APPROVE_REJECT_FAILURE = "APPROVE_REJECT_FAILURE";

export const BARCODE_REQUEST = "BARCODE_REQUEST";
export const BARCODE_SUCCESS = "BARCODE_SUCCESS";
export const BARCODE_FAILURE = "BARCODE_FAILURE";

export const LOYALTY_TRANSACTION_TYPES_REQUEST =
  "LOYALTY_TRANSACTION_TYPES_REQUEST";
export const LOYALTY_TRANSACTION_TYPES_SUCCESS =
  "LOYALTY_TRANSACTION_TYPES_SUCCESS";
export const LOYALTY_TRANSACTION_TYPES_FAILURE =
  "LOYALTY_TRANSACTION_TYPES_FAILURE";

export const TAXABLE_PRIZE_LIST_FILTERS_REQUEST =
  "TAXABLE_PRIZE_LIST_FILTERS_REQUEST";
export const TAXABLE_PRIZE_LIST_FILTERS_SUCCESS =
  "TAXABLE_PRIZE_LIST_FILTERS_SUCCESS";
export const TAXABLE_PRIZE_LIST_FILTERS_FAILURE =
  "TAXABLE_PRIZE_LIST_FILTERS_FAILURE";

export const TAXABLE_PRIZE_DOWNLOAD_PDF_REQUEST =
  "TAXABLE_PRIZE_DOWNLOAD_PDF_REQUEST";
export const TAXABLE_PRIZE_DOWNLOAD_PDF_FAILURE =
  "TAXABLE_PRIZE_DOWNLOAD_PDF_FAILURE";

export const TAXABLE_PRIZE_GENERATE_PDF_REQUEST =
  "TAXABLE_PRIZE_GENERATE_PDF_REQUEST";
export const TAXABLE_PRIZE_GENERATE_PDF_SUCCESS =
  "TAXABLE_PRIZE_GENERATE_PDF_SUCCESS";
export const TAXABLE_PRIZE_GENERATE_PDF_FAILURE =
  "TAXABLE_PRIZE_GENERATE_PDF_FAILURE";

export const WINNINGS_LIST_REQUEST = "WINNINGS_LIST_REQUEST";
export const WINNINGS_LIST_SUCCESS = "WINNINGS_LIST_SUCCESS";
export const WINNINGS_LIST_FAILURE = "WINNINGS_LIST_FAILURE";

//Campaign
export const MANAGEMENT_LIST_FILTERS_REQUEST =
  "MANAGEMENT_LIST_FILTERS_REQUEST";
export const MANAGEMENT_LIST_FILTERS_SUCCESS =
  "MANAGEMENT_LIST_FILTERS_SUCCESS";
export const MANAGEMENT_LIST_FILTERS_FAILURE =
  "MANAGEMENT_LIST_FILTERS_FAILURE";
export const MANAGEMENT_LIST_REQUEST = "MANAGEMENT_LIST_REQUEST";
export const MANAGEMENT_LIST_SUCCESS = "MANAGEMENT_LIST_SUCCESS";
export const MANAGEMENT_LIST_FAILURE = "MANAGEMENT_LIST_FAILURE";
export const CAMPAIGN_MANAGEMENT_LIST_RELOAD =
  "CAMPAIGN_MANAGEMENT_LIST_RELOAD";

export const CAMPAIGN_DETAILS_REQUEST = "CAMPAIGN_DETAILS_REQUEST";
export const CAMPAIGN_DETAILS_SUCCESS = "CAMPAIGN_DETAILS_SUCCESS";
export const CAMPAIGN_DETAILS_FAILURE = "CAMPAIGN_DETAILS_FAILURE";

export const CAMPAIGN_FULL_DETAILS_REQUEST = "CAMPAIGN_FULL_DETAILS_REQUEST";
export const CAMPAIGN_FULL_DETAILS_SUCCESS = "CAMPAIGN_FULL_DETAILS_SUCCESS";
export const CAMPAIGN_FULL_DETAILS_FAILURE = "CAMPAIGN_FULL_DETAILS_FAILURE";

export const CAMPAIGN_REWARD_RULE_REQUEST = "CAMPAIGN_REWARD_RULE_REQUEST";
export const CAMPAIGN_REWARD_RULE_SUCCESS = "CAMPAIGN_REWARD_RULE_SUCCESS";
export const CAMPAIGN_REWARD_RULE_FAILURE = "CAMPAIGN_REWARD_RULE_FAILURE";

export const CAMPAIGN_SAVE_REQUEST = "CAMPAIGN_SAVE_REQUEST";
export const CAMPAIGN_SAVE_SUCCESS = "CAMPAIGN_SAVE_SUCCESS";
export const CAMPAIGN_SAVE_FAILURE = "CAMPAIGN_SAVE_FAILURE";

export const CAMPAIGN_CHANGE_STATUS_REQUEST = "CAMPAIGN_CHANGE_STATUS_REQUEST";
export const CAMPAIGN_GET_COMMUNICATIONS_REQUEST =
  "CAMPAIGN_GET_COMMUNICATIONS";
export const CAMPAIGN_COMMUNICATIONS_SUCCESS =
  "CAMPAIGN_COMMUNICATIONS_SUCCESS";
export const CAMPAIGN_COMMUNICATIONS_FAILURE =
  "CAMPAIGN_COMMUNICATIONS_FAILURE";

export const BUDGET_DETAILS_REQUEST = "BUDGET_DETAILS_REQUEST";
export const REF_DETAILS_REQUEST = "REF_DETAILS_REQUEST";
export const REWARDS_DETAILS_SUCCESS = "REWARDS_DETAILS_SUCCESS";
export const REWARDS_DETAILS_FAILURE = "REWARDS_DETAILS_FAILURE";

export const PROMO_CODES_DETAILS_REQUEST = "PROMO_CODES_DETAILS_REQUEST";
export const PROMO_CODES_DETAILS_SUCCESS = "PROMO_CODES_DETAILS_SUCCESS";
export const PROMO_CODES_DETAILS_FAILURE = "PROMO_CODES_DETAILS_FAILURE";

export const LIMIT_CONFIGURATION_REQUEST = "LIMIT_CONFIGURATION_REQUEST";
export const LIMIT_CONFIGURATION_SUCCESS = "LIMIT_CONFIGURATION_SUCCESS";
export const LIMIT_CONFIGURATION_FAILURE = "LIMIT_CONFIGURATION_FAILURE";

export const LIMIT_CONFIG_HISTORY_REQUEST = "LIMIT_CONFIG_HISTORY_REQUEST";
export const LIMIT_CONFIG_HISTORY_SUCCESS = "LIMIT_CONFIG_HISTORY_SUCCESS";
export const LIMIT_CONFIG_HISTORY_FAILURE = "LIMIT_CONFIG_HISTORY_FAILURE";

export const LIMIT_CONFIG_EDIT_REQUEST = "LIMIT_CONFIG_EDIT_REQUEST";
export const LIMIT_CONFIG_EDIT_SUCCESS = "LIMIT_CONFIG_EDIT_SUCCESS";
export const LIMIT_CONFIG_EDIT_FAILURE = "LIMIT_CONFIG_EDIT_FAILURE";

export const SCHEDULED_PROGRAM_REQUEST = "SCHEDULED_PROGRAM_REQUEST";
export const SCHEDULED_PROGRAM_SUCCESS = "SCHEDULED_PROGRAM_SUCCESS";
export const SCHEDULED_PROGRAM_FAILURE = "SCHEDULED_PROGRAM_FAILURE";

export const PROGRAM_REQUEST = "PROGRAM_REQUEST";
export const PROGRAM_SUCCESS = "PROGRAM_SUCCESS";
export const PROGRAM_FAILURE = "PROGRAM_FAILURE";

export const PROGRAM_BONUS_REQUEST = "PROGRAM_BONUS_REQUEST";
export const PROGRAM_BONUS_SUCCESS = "PROGRAM_BONUS_SUCCESS";
export const PROGRAM_BONUS_FAILURE = "PROGRAM_BONUS_FAILURE";

export const PROGRAM_SCALE_REQUEST = "PROGRAM_SCALE_REQUEST";
export const PROGRAM_SCALE_SUCCESS = "PROGRAM_SCALE_SUCCESS";
export const PROGRAM_SCALE_FAILURE = "PROGRAM_SCALE_FAILURE";

export const CAMPAIGN_REWARD_TYPES_REQUEST = "CAMPAIGN_REWARD_TYPES_REQUEST";
export const CAMPAIGN_REWARD_TYPES_SUCCESS = "CAMPAIGN_REWARD_TYPES_SUCCESS";
export const CAMPAIGN_REWARD_TYPES_FAILURE = "CAMPAIGN_REWARD_TYPES_FAILURE";
export const CAMPAIGN_REWARD_EVENT_TYPES_REQUEST =
  "CAMPAIGN_REWARD_EVENT_TYPES_REQUEST";
export const CAMPAIGN_REWARD_EVENT_TYPES_SUCCESS =
  "CAMPAIGN_REWARD_EVENT_TYPES_SUCCESS";
export const CAMPAIGN_REWARD_EVENT_TYPES_FAILURE =
  "CAMPAIGN_REWARD_EVENT_TYPES_FAILURE";
export const CAMPAIGN_REWARD_EXCLUDED_PLAYERS_REQUEST =
  "CAMPAIGN_REWARD_EXCLUDED_PLAYERS_REQUEST";
export const CAMPAIGN_REWARD_EXCLUDED_PLAYERS_SUCCESS =
  "CAMPAIGN_REWARD_EXCLUDED_PLAYERS_SUCCESS";
export const CAMPAIGN_REWARD_EXCLUDED_PLAYERS_FAILURE =
  "CAMPAIGN_REWARD_EXCLUDED_PLAYERS_FAILURE";
export const CAMPAIGN_REWARD_BUDGET_TYPES_REQUEST =
  "CAMPAIGN_REWARD_BUDGET_TYPES_REQUEST";
export const CAMPAIGN_REWARD_BUDGET_TYPES_SUCCESS =
  "CAMPAIGN_REWARD_BUDGET_TYPES_SUCCESS";
export const CAMPAIGN_REWARD_BUDGET_TYPES_FAILURE =
  "CAMPAIGN_REWARD_BUDGET_TYPES_FAILURE";
export const CAMPAIGN_TAGS_REQUEST = "CAMPAIGN_TAGS_REQUEST";
export const CAMPAIGN_TAGS_SUCCESS = "CAMPAIGN_TAGS_SUCCESS";
export const CAMPAIGN_TAGS_FAILURE = "CAMPAIGN_TAGS_FAILURE";

export const CAMPAIGN_PARAMETER_TYPE_OPERATOR_REQUEST =
  "CAMPAIGN_PARAMETER_TYPE_OPERATOR_REQUEST";
export const CAMPAIGN_PARAMETER_TYPE_OPERATOR_FAILURE =
  "CAMPAIGN_PARAMETER_TYPE_OPERATOR_FAILURE";
export const CAMPAIGN_PARAMETER_TYPE_OPERATOR_SUCCESS =
  "CAMPAIGN_PARAMETER_TYPE_OPERATOR_SUCCESS";

export const CAMPAIGN_PARAMETER_DEFINITIONS_REQUEST =
  "CAMPAIGN_PARAMETER_DEFINITIONS_REQUEST";
export const CAMPAIGN_PARAMETER_DEFINITIONS_FAILURE =
  "CAMPAIGN_PARAMETER_DEFINITIONS_FAILURE";
export const CAMPAIGN_PARAMETER_DEFINITIONS_SUCCESS =
  "CAMPAIGN_PARAMETER_DEFINITIONS_SUCCESS";

export const COMMUNICATIONS_LIST_FILTERS_REQUEST =
  "COMMUNICATIONS_LIST_FILTERS_REQUEST";
export const COMMUNICATIONS_LIST_FILTERS_SUCCESS =
  "COMMUNICATIONS_LIST_FILTERS_SUCCESS";
export const COMMUNICATIONS_LIST_FILTERS_FAILURE =
  "COMMUNICATIONS_LIST_FILTERS_FAILURE";

export const CAMPAIGN_CUSTOM_REWARD_REQUEST = "CAMPAIGN_CUSTOM_REWARD_REQUEST";
export const CAMPAIGN_CUSTOM_REWARD_SUCCESS = "CAMPAIGN_CUSTOM_REWARD_SUCCESS";
export const CAMPAIGN_CUSTOM_REWARD_FAILURE = "CAMPAIGN_CUSTOM_REWARD_FAILURE";

export const COMMUNICATIONS_LIST_RELOAD = "COMMUNICATIONS_LIST_RELOAD";
export const COMMUNICATIONS_LIST_REQUEST = "COMMUNICATIONS_LIST_REQUEST";
export const COMMUNICATIONS_LIST_SUCCESS = "COMMUNICATIONS_LIST_SUCCESS";
export const COMMUNICATIONS_LIST_FAILURE = "COMMUNICATIONS_LIST_FAILURE";

export const COMMUNICATIONS_DISABLE_REQUEST = "COMMUNICATIONS_DISABLE_REQUEST";
export const COMMUNICATIONS_DISABLE_SUCCESS = "COMMUNICATIONS_DISABLE_SUCCESS";
export const COMMUNICATIONS_DISABLE_FAILURE = "COMMUNICATIONS_DISABLE_FAILURE";

export const COMMUNICATIONS_MANAGEMENT_TYPES_REQUEST =
  "COMMUNICATIONS_MANAGEMENT_TYPES_REQUEST";
export const COMMUNICATIONS_MANAGEMENT_TYPES_SUCCESS =
  "COMMUNICATIONS_MANAGEMENT_TYPES_SUCCESS";
export const COMMUNICATIONS_MANAGEMENT_TYPES_FAILURE =
  "COMMUNICATIONS_MANAGEMENT_TYPES_FAILURE";

export const COMMUNICATIONS_DELIVERY_CHANNELS_REQUEST =
  "COMMUNICATIONS_DELIVERY_CHANNELS_REQUEST";
export const COMMUNICATIONS_DELIVERY_CHANNELS_SUCCESS =
  "COMMUNICATIONS_DELIVERY_CHANNELS_SUCCESS";
export const COMMUNICATIONS_DELIVERY_CHANNELS_FAILURE =
  "COMMUNICATIONS_DELIVERY_CHANNELS_FAILURE";

export const COMMUNICATIONS_TYPES_REQUEST = "COMMUNICATIONS_TYPES_REQUEST";
export const COMMUNICATIONS_TYPES_SUCCESS = "COMMUNICATIONS_TYPES_SUCCESS";
export const COMMUNICATIONS_TYPES_FAILURE = "COMMUNICATIONS_TYPES_FAILURE";

export const COMMUNICATIONS_SEND_TYPES_REQUEST =
  "COMMUNICATIONS_SEND_TYPES_REQUEST";
export const COMMUNICATIONS_SEND_TYPES_SUCCESS =
  "COMMUNICATIONS_SEND_TYPES_SUCCESS";
export const COMMUNICATIONS_SEND_TYPES_FAILURE =
  "COMMUNICATIONS_SEND_TYPES_FAILURE";

export const COMMUNICATIONS_EVENT_TYPES_REQUEST =
  "COMMUNICATIONS_EVENT_TYPES_REQUEST";
export const COMMUNICATIONS_EVENT_TYPES_SUCCESS =
  "COMMUNICATIONS_EVENT_TYPES_SUCCESS";
export const COMMUNICATIONS_EVENT_TYPES_FAILURE =
  "COMMUNICATIONS_EVENT_TYPES_FAILURE";

export const COMMUNICATIONS_TEMPLATES_REQUEST =
  "COMMUNICATIONS_TEMPLATES_REQUEST";
export const COMMUNICATIONS_TEMPLATES_SUCCESS =
  "COMMUNICATIONS_TEMPLATES_SUCCESS";
export const COMMUNICATIONS_TEMPLATES_FAILURE =
  "COMMUNICATIONS_TEMPLATES_FAILURE";

export const COMMUNICATIONS_OFFSET_TYPES_REQUEST =
  "COMMUNICATIONS_OFFSET_TYPES_REQUEST";
export const COMMUNICATIONS_OFFSET_TYPES_SUCCESS =
  "COMMUNICATIONS_OFFSET_TYPES_SUCCESS";
export const COMMUNICATIONS_OFFSET_TYPES_FAILURE =
  "COMMUNICATIONS_OFFSET_TYPES_FAILURE";

export const COMMUNICATIONS_EXTRA_PARAMETERS_REQUEST =
  "COMMUNICATIONS_EXTRA_PARAMETERS_REQUEST";
export const COMMUNICATIONS_EXTRA_PARAMETERS_SUCCESS =
  "COMMUNICATIONS_EXTRA_PARAMETERS_SUCCESS";
export const COMMUNICATIONS_EXTRA_PARAMETERS_FAILURE =
  "COMMUNICATIONS_EXTRA_PARAMETERS_FAILURE";

export const COMMUNICATIONS_SAVE_REQUEST = "COMMUNICATIONS_SAVE_REQUEST";
export const COMMUNICATIONS_SAVE_SUCCESS = "COMMUNICATIONS_SAVE_SUCCESS";
export const COMMUNICATIONS_SAVE_FAILURE = "COMMUNICATIONS_SAVE_FAILURE";

export const COMMUNICATIONS_DETAIL_REQUEST = "COMMUNICATIONS_DETAIL_REQUEST";
export const COMMUNICATIONS_DETAIL_SUCCESS = "COMMUNICATIONS_DETAIL_SUCCESS";
export const COMMUNICATIONS_DETAIL_FAILURE = "COMMUNICATIONS_DETAIL_FAILURE";

export const LOY_REWARD_TYPES_REQUEST = "LOY_REWARD_TYPES_REQUEST";
export const LOY_REWARD_TYPES_SUCCESS = "LOY_REWARD_TYPES_SUCCESS";
export const LOY_REWARD_TYPES_FAILURE = "LOY_REWARD_TYPES_FAILURE";

export const LOY_CURRENCIES_REQUEST = "LOY_CURRENCIES_REQUEST";
export const LOY_CURRENCIES_SUCCESS = "LOY_CURRENCIES_SUCCESS";
export const LOY_CURRENCIES_FAILURE = "LOY_CURRENCIES_FAILURE";

export const LOY_CREDIT_TYPE_REQUEST = "LOY_CREDIT_TYPE_REQUEST";
export const LOY_CREDIT_TYPE_SUCCESS = "LOY_CREDIT_TYPE_SUCCESS";
export const LOY_CREDIT_TYPE_FAILURE = "LOY_CREDIT_TYPE_FAILURE";

export const SAVE_NEW_LOY_BONUS_REQUEST = "SAVE_NEW_LOY_BONUS_REQUEST";
export const SAVE_NEW_LOY_BONUS_SUCCESS = "SAVE_NEW_LOY_BONUS_SUCCESS";
export const SAVE_NEW_LOY_BONUS_FAILURE = "SAVE_NEW_LOY_BONUS_FAILURE";

export const REMOVE_LEVEL_BONUS_REQUEST = "REMOVE_LEVEL_BONUS_REQUEST";
export const REMOVE_LEVEL_BONUS_SUCCESS = "REMOVE_LEVEL_BONUS_SUCCESS";
export const REMOVE_LEVEL_BONUS_FAILURE = "REMOVE_LEVEL_BONUS_FAILURE";

export const BONUS_LEVELS_REQUEST = "BONUS_LEVELS_REQUEST";
export const BONUS_LEVELS_SUCCESS = "BONUS_LEVELS_SUCCESS";
export const BONUS_LEVELS_FAILURE = "BONUS_LEVELS_FAILURE";

export const SAVE_BONUS_LEVEL_REQUEST = "SAVE_BONUS_LEVEL_REQUEST";
export const SAVE_BONUS_LEVEL_SUCCESS = "SAVE_BONUS_LEVEL_SUCCESS";
export const SAVE_BONUS_LEVEL_FAILURE = "SAVE_BONUS_LEVEL_FAILURE";

export const DELETE_BONUS_REQUEST = "DELETE_BONUS_REQUEST";
export const DELETE_BONUS_SUCCESS = "DELETE_BONUS_SUCCESS";
export const DELETE_BONUS_FAILURE = "DELETE_BONUS_FAILURE";

export const ORDER_ACTION_REQUEST = "ORDER_ACTION_REQUEST";
export const ORDER_ACTION_SUCCESS = "ORDER_ACTION_SUCCESS";
export const ORDER_ACTION_FAILURE = "ORDER_ACTION_FAILURE";

export const ORDER_TYPE_REQUEST = "ORDER_TYPE_REQUEST";
export const ORDER_TYPE_SUCCESS = "ORDER_TYPE_SUCCESS";
export const ORDER_TYPE_FAILURE = "ORDER_TYPE_FAILURE";

export const SAVE_ORDER_REQUEST = "SAVE_ORDER_REQUEST";
export const SAVE_ORDER_SUCCESS = "SAVE_ORDER_SUCCESS";
export const SAVE_ORDER_FAILURE = "SAVE_ORDER_FAILURE";

export const CLUB_CARD_HISTORY_SUCCESS = "CLUB_CARD_HISTORY_SUCCESS";
export const CLUB_CARD_HISTORY_FAILURE = "CLUB_CARD_HISTORY_FAILURE";
export const CLUB_CARD_HISTORY_REQUEST = "CLUB_CARD_HISTORY_REQUEST";

export const CLUB_CARD_DETAILS_SUCCESS = "CLUB_CARD_DETAILS_SUCCESS";
export const CLUB_CARD_DETAILS_FAILURE = "CLUB_CARD_DETAILS_FAILURE";
export const CLUB_CARD_DETAILS_REQUEST = "CLUB_CARD_DETAILS_REQUEST";

export const CLUB_CARD_EMAIL_SUCCESS = "CLUB_CARD_EMAIL_SUCCESS";
export const CLUB_CARD_EMAIL_FAILURE = "CLUB_CARD_EMAIL_FAILURE";
export const CLUB_CARD_EMAIL_REQUEST = "CLUB_CARD_EMAIL_REQUEST";

export const PROMO_CODE_FILTERS_REQUEST = "PROMO_CODE_FILTERS_REQUEST";
export const PROMO_CODE_FILTERS_SUCCESS = "PROMO_CODE_FILTERS_SUCCESS";
export const PROMO_CODE_FILTERS_FAILURE = "PROMO_CODE_FILTERS_FAILURE";

export const PROMO_CODE_REQUEST = "PROMO_CODE_REQUEST";
export const PROMO_CODE_SUCCESS = "PROMO_CODE_SUCCESS";
export const PROMO_CODE_FAILURE = "PROMO_CODE_FAILURE";

export const PROMO_CODE_CAMPAIGNS_REQUEST = "PROMO_CODE_CAMPAIGNS_REQUEST";
export const PROMO_CODE_CAMPAIGNS_SUCCESS = "PROMO_CODE_CAMPAIGNS_SUCCESS";
export const PROMO_CODE_CAMPAIGNS_FAILURE = "PROMO_CODE_CAMPAIGNS_FAILURE";

export const PROMO_CODE_AMOUNT_TYPES_REQUEST =
  "PROMO_CODE_AMOUNT_TYPES_REQUEST";
export const PROMO_CODE_AMOUNT_TYPES_SUCCESS =
  "PROMO_CODE_AMOUNT_TYPES_SUCCESS";
export const PROMO_CODE_AMOUNT_TYPES_FAILURE =
  "PROMO_CODE_AMOUNT_TYPES_FAILURE";

export const SAVE_PROMO_CODE_REQUEST = "SAVE_PROMO_CODE_REQUEST";
export const SAVE_PROMO_CODE_SUCCESS = "SAVE_PROMO_CODE_SUCCESS";
export const SAVE_PROMO_CODE_FAILURE = "SAVE_PROMO_CODE_FAILURE";

export const PROMO_CODE_RELOAD = "PROMO_CODE_RELOAD";

export const BLACKLIST_FILTERS_REQUEST = "BLACKLIST_FILTERS_REQUEST";
export const BLACKLIST_FILTERS_SUCCESS = "BLACKLIST_FILTERS_SUCCESS";
export const BLACKLIST_FILTERS_FAILURE = "BLACKLIST_FILTERS_FAILURE";

export const BLACKLIST_TYPE_REQUEST = "BLACKLIST_TYPE_REQUEST";
export const BLACKLIST_TYPE_SUCCESS = "BLACKLIST_TYPE_SUCCESS";
export const BLACKLIST_TYPE_FAILURE = "BLACKLIST_TYPE_FAILURE";

export const BLACKLIST_VALUE_TYPE_REQUEST = "BLACKLIST_VALUE_TYPE_REQUEST";
export const BLACKLIST_VALUE_TYPE_SUCCESS = "BLACKLIST_VALUE_TYPE_SUCCESS";
export const BLACKLIST_VALUE_TYPE_FAILURE = "BLACKLIST_VALUE_TYPE_FAILURE";

export const BLACKLIST_REQUEST = "BLACKLIST_REQUEST";
export const BLACKLIST_SUCCESS = "BLACKLIST_SUCCESS";
export const BLACKLIST_FAILURE = "BLACKLIST_FAILURE";

export const BLACKLIST_MANAGE_REQUEST = "BLACKLIST_MANAGE_REQUEST";
export const BLACKLIST_MANAGE_SUCCESS = "BLACKLIST_MANAGE_SUCCESS";
export const BLACKLIST_MANAGE_FAILURE = "BLACKLIST_MANAGE_FAILURE";

export const BLACKLIST_ADD_REQUEST = "BLACKLIST_ADD_REQUEST";
export const BLACKLIST_ADD_SUCCESS = "BLACKLIST_ADD_SUCCESS";
export const BLACKLIST_ADD_FAILURE = "BLACKLIST_ADD_FAILURE";

export const BLACKLIST_EDIT_REQUEST = "BLACKLIST_EDIT_REQUEST";
export const BLACKLIST_EDIT_SUCCESS = "BLACKLIST_EDIT_SUCCESS";
export const BLACKLIST_EDIT_FAILURE = "BLACKLIST_EDIT_FAILURE";

export const BLACKLIST_REMOVE_REQUEST = "BLACKLIST_REMOVE_REQUEST";
export const BLACKLIST_REMOVE_SUCCESS = "BLACKLIST_REMOVE_SUCCESS";
export const BLACKLIST_REMOVE_FAILURE = "BLACKLIST_REMOVE_FAILURE";

export const BLACKLIST_RELOAD = "BLACKLIST_RELOAD";

export const CLUB_CARD_ACTIONS_SUCCESS = "CLUB_CARD_ACTIONS_SUCCESS";
export const CLUB_CARD_ACTIONS_FAILURE = "CLUB_CARD_ACTIONS_FAILURE";
export const CLUB_CARD_ACTIONS_REQUEST = "CLUB_CARD_ACTIONS_REQUEST";

export const CAMPAIGN_ACCEPTANCE_TYPE_REQUEST =
  "CAMPAIGN_ACCEPTANCE_TYPE_REQUEST";
export const CAMPAIGN_ACCEPTANCE_TYPE_SUCCESS =
  "CAMPAIGN_ACCEPTANCE_TYPE_SUCCESS";
export const CAMPAIGN_ACCEPTANCE_TYPE_FAILURE =
  "CAMPAIGN_ACCEPTANCE_TYPE_FAILURE";

export const PRINT_CARD_REQUEST = "PRINT_CARD_REQUEST";
export const SAVE_PRINT_REQUEST_SUCCESS = "SAVE_PRINT_REQUEST_SUCCESS";
export const SAVE_PRINT_REQUEST_FAILURE = "SAVE_PRINT_REQUEST_FAILURE";

export const ACTION_LOG_REQUEST = "ACTION_LOG_REQUEST";
export const ACTION_LOG_REQUEST_FAILURE = "ACTION_LOG_REQUEST_FAILURE";
export const ACTION_LOG_REQUEST_SUCCESS = "ACTION_LOG_REQUEST_SUCCESS";

export const REGENERATE_CARD_REQUEST = "REGENERATE_CARD_REQUEST";
export const REGENERATE_CARD_FAILURE = "REGENERATE_CARD_FAILURE";
export const REGENERATE_CARD_SUCCESS = "REGENERATE_CARD_SUCCESS";

export const RAF_LIST_REQUEST = "RAF_LIST_REQUEST";
export const RAF_LIST_SUCCESS = "RAF_LIST_SUCCESS";
export const RAF_LIST_FAILURE = "RAF_LIST_FAILURE";
export const RAF_DETAIL_SUCCESS = "RAF_DETAIL_SUCCESS";

export const PLAYER_FAVORITES_REQUEST = "PLAYER_FAVORITES_REQUEST";
export const PLAYER_FAVORITES_SUCCESS = "PLAYER_FAVORITES_SUCCESS";
export const PLAYER_FAVORITES_FAILURE = "PLAYER_FAVORITES_FAILURE";

export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAILURE = "CATEGORY_LIST_FAILURE";

export const LOCATIONS_LIST_REQUEST = "LOCATIONS_LIST_REQUEST";
export const LOCATIONS_LIST_SUCCESS = "LOCATIONS_LIST_SUCCESS";
export const LOCATIONS_LIST_FAILURE = "LOCATIONS_LIST_FAILURE";

export const LOCATIONS_ADMIN_LIST_REQUEST = "LOCATIONS_ADMIN_LIST_REQUEST";
export const LOCATIONS_ADMIN_LIST_SUCCESS = "LOCATIONS_ADMIN_LIST_SUCCESS";
export const LOCATIONS_ADMIN_LIST_FAILURE = "LOCATIONS_ADMIN_LIST_FAILURE";

export const SAVE_FAVORITE_REQUEST = "SAVE_FAVORITE_REQUEST";
export const SAVE_FAVORITE_SUCCESS = "SAVE_FAVORITE_SUCCESS";
export const SAVE_FAVORITE_FAILURE = "SAVE_FAVORITE_FAILURE";

//Admin
export const NETWORK_BANS_ADMIN_FILTERS_REQUEST =
  "NETWORK_BANS_ADMIN_FILTERS_REQUEST";
export const NETWORK_BANS_ADMIN_FILTERS_SUCCESS =
  "NETWORK_BANS_ADMIN_FILTERS_SUCCESS";
export const NETWORK_BANS_ADMIN_FILTERS_FAILURE =
  "NETWORK_BANS_ADMIN_FILTERS_FAILURE";

export const NETWORK_BANS_ADMIN_LIST_REQUEST =
  "NETWORK_BANS_ADMIN_LIST_REQUEST";
export const NETWORK_BANS_ADMIN_LIST_SUCCESS =
  "NETWORK_BANS_ADMIN_LIST_SUCCESS";
export const NETWORK_BANS_ADMIN_LIST_FAILURE =
  "NETWORK_BANS_ADMIN_LIST_FAILURE";

export const NETWORK_BANS_ADD_CATEGORY_REQUEST =
  "NETWORK_BANS_ADD_CATEGORY_REQUEST";
export const NETWORK_BANS_ADD_CATEGORY_SUCCESS =
  "NETWORK_BANS_ADD_CATEGORY_SUCCESS";
export const NETWORK_BANS_ADD_CATEGORY_FAILURE =
  "NETWORK_BANS_ADD_CATEGORY_REQUEST";

export const NETWORK_BANS_ADD_GAMES_REQUEST = "NETWORK_BANS_ADD_GAMES_REQUEST";
export const NETWORK_BANS_ADD_GAMES_SUCCESS = "NETWORK_BANS_ADD_GAMES_SUCCESS";
export const NETWORK_BANS_ADD_GAMES_FAILURE = "NETWORK_BANS_ADD_GAMES_FAILURE";

export const NETWORK_BANS_ADD_BAN_REQUEST = "NETWORK_BANS_ADD_BAN_REQUEST";
export const NETWORK_BANS_ADD_BAN_SUCCESS = "NETWORK_BANS_ADD_BAN_SUCCESS";
export const NETWORK_BANS_ADD_BAN_FAILURE = "NETWORK_BANS_ADD_BAN_FAILURE";

export const NETWORK_BANS_REMOVE_BAN_REQUEST =
  "NETWORK_BANS_REMOVE_BAN_REQUEST";
export const NETWORK_BANS_REMOVE_BAN_SUCCESS =
  "NETWORK_BANS_REMOVE_BAN_SUCCESS";
export const NETWORK_BANS_REMOVE_BAN_FAILURE =
  "NETWORK_BANS_REMOVE_BAN_FAILURE";
export const NETWORK_BANS_LIST_RELOAD = "NETWORK_BANS_LIST_RELOAD";

export const COOLDOWN_PERIOD_REQUEST = "COOLDOWN_PERIOD_REQUEST";
export const COOLDOWN_PERIOD_SUCCESS = "COOLDOWN_PERIOD_SUCCESS";
export const COOLDOWN_PERIOD_FAILURE = "COOLDOWN_PERIOD_FAILURE";

export const COOLDOWN_SAVE_REQUEST = "COOLDOWN_SAVE_REQUEST";

export const MODAL_CONFIG_REQUEST = "MODAL_CONFIG_REQUEST";
export const MODAL_CONFIG_SUCCESS = "MODAL_CONFIG_SUCCESS";
export const MODAL_CONFIG_FAILURE = "MODAL_CONFIG_FAILURE";

export const TAKE_IN_CHARGE_REQUEST = "TAKE_IN_CHARGE_REQUEST";
export const TAKE_IN_CHARGE_SUCCESS = "TAKE_IN_CHARGE_SUCCESS";
export const TAKE_IN_CHARGE_FAILURE = "TAKE_IN_CHARGE_FAILURE";

export const WINNINGS_LIST_RELOAD = "WINNINGS_LIST_RELOAD";

export const DEDUCT_AMOUNT_REQUEST = "DEDUCT_AMOUNT_REQUEST";
export const DEDUCT_AMOUNT_SUCCESS = "DEDUCT_AMOUNT_SUCCESS";
export const DEDUCT_AMOUNT_FAILURE = "DEDUCT_AMOUNT_FAILURE";

export const TAXABLE_PRIZE_PAYMENT_REQUEST = "TAXABLE_PRIZE_PAYMENT_REQUEST";
export const TAXABLE_PRIZE_PAYMENT_SUCCESS = "TAXABLE_PRIZE_PAYMENT_SUCCESS";
export const TAXABLE_PRIZE_PAYMENT_FAILURE = "TAXABLE_PRIZE_PAYMENT_FAILURE";

export const TRANSACTION_LOGS_REQUEST = "TRANSACTION_LOGS_REQUEST";
export const TRANSACTION_LOGS_SUCCESS = "TRANSACTION_LOGS_SUCCESS";
export const TRANSACTION_LOGS_FAILURE = "TRANSACTION_LOGS_FAILURE";

export const DESK_FILTER_REQUEST = "DESK_FILTER_REQUEST";
export const DESK_FILTER_SUCCESS = "DESK_FILTER_SUCCESS";
export const DESK_FILTER_FAILURE = "DESK_FILTER_FAILURE";

export const DESK_SEARCH_REQUEST = "DESK_SEARCH_REQUEST";
export const DESK_SEARCH_SUCCESS = "DESK_SEARCH_SUCCESS";
export const DESK_SEARCH_FAILURE = "DESK_SEARCH_FAILURE";

export const DESK_APPROVE_REJECT_REQUEST = "DESK_APPROVE_REJECT_REQUEST";
export const DESK_APPROVE_REJECT_SUCCESS = "DESK_APPROVE_REJECT_SUCCESS";
export const DESK_APPROVE_REJECT_FAILURE = "DESK_APPROVE_REJECT_FAILURE";
export const DESK_EXTERNALIDS_REQUEST = "DESK_EXTERNALIDS_REQUEST";
export const DESK_EXTERNALIDS_REQUEST_SUCCESS =
  "DESK_EXTERNALIDS_REQUEST_SUCCESS";
export const DESK_EXTERNALIDS_REQUEST_FAILURE =
  "DESK_EXTERNALIDS_REQUEST_FAILURE";

export const DESK_REWARD_RELOAD = "DESK_REWARD_RELOAD";

export const CONTACT_VERIFICATION_REQUEST = "CONTACT_VERIFICATION_REQUEST";
export const CONTACT_VERIFICATION_SUCCESS = "CONTACT_VERIFICATION_SUCCESS";
export const CONTACT_VERIFICATION_FAILURE = "CONTACT_VERIFICATION_FAILURE";
export const RELOAD_CONTACT_VERIFICATION_LIST =
  "RELOAD_CONTACT_VERIFICATION_LIST";
export const SEND_PIN_REQUEST = "SEND_PIN_REQUEST";
export const SEND_PIN_SUCCESS = "SEND_PIN_SUCCESS";
export const SEND_PIN_FAILURE = "SEND_PIN_FAILURE";

export const VERIFY_PIN_REQUEST = "VERIFY_PIN_REQUEST";
export const VERIFY_PIN_SUCCESS = "VERIFY_PIN_SUCCESS";
export const VERIFY_PIN_FAILURE = "VERIFY_PIN_FAILURE";

export const PLAYER_CONTACTS_VERIFICATION_FILTERS_REQUEST =
  "PLAYER_CONTACTS_VERIFICATION_FILTERS_REQUEST";
export const PLAYER_CONTACTS_VERIFICATION_FILTERS_SUCCESS =
  "PLAYER_CONTACTS_VERIFICATION_FILTERS_SUCCESS";
export const PLAYER_CONTACTS_VERIFICATION_FILTERS_FAILURE =
  "PLAYER_CONTACTS_VERIFICATION_FILTERS_FAILURE";
export const PLAYER_CONTACTS_VERIFICATION_LIST_RELOAD =
  "PLAYER_CONTACTS_VERIFICATION_LIST_RELOAD";

export const PLAYER_CONTACTS_VERIFICATION_LIST_REQUEST =
  "PLAYER_CONTACTS_VERIFICATION_LIST_REQUEST";
export const PLAYER_CONTACTS_VERIFICATION_LIST_SUCCESS =
  "PLAYER_CONTACTS_VERIFICATION_LIST_SUCCESS";
export const PLAYER_CONTACTS_VERIFICATION_LIST_FAILURE =
  "PLAYER_CONTACTS_VERIFICATION_LIST_FAILURE";

export const PLAYER_CONTACTS_VERIFICATION_CHANGE_VERIFICATION_REQUEST =
  "PLAYER_CONTACTS_VERIFICATION_CHANGE_VERIFICATION_REQUEST";
export const PLAYER_CONTACTS_VERIFICATION_CHANGE_VERIFICATION_SUCCESS =
  "PLAYER_CONTACTS_VERIFICATION_CHANGE_VERIFICATION_SUCCESS";
export const PLAYER_CONTACTS_VERIFICATION_CHANGE_VERIFICATION_FAILURE =
  "PLAYER_CONTACTS_VERIFICATION_CHANGE_VERIFICATION_FAILURE";

export const SENSITIVE_VIP_PLAYER_DETAILS_REQUEST =
  "SENSITIVE_VIP_PLAYER_DETAILS_REQUEST";
export const SENSITIVE_VIP_PLAYER_DETAILS_SUCCESS =
  "SENSITIVE_VIP_PLAYER_DETAILS_SUCCESS";
export const SENSITIVE_VIP_PLAYER_DETAILS_FAILURE =
  "SENSITIVE_VIP_PLAYER_DETAILS_FAILURE";

export const SENSITIVE_VIP_PLAYER_HISTORY_REQUEST =
  "SENSITIVE_VIP_PLAYER_HISTORY_REQUEST";
export const SENSITIVE_VIP_PLAYER_HISTORY_SUCCESS =
  "SENSITIVE_VIP_PLAYER_HISTORY_SUCCESS";
export const SENSITIVE_VIP_PLAYER_HISTORY_FAILURE =
  "SENSITIVE_VIP_PLAYER_HISTORY_FAILURE";

export const SENSITIVE_VIP_PLAYER_MARK_UNMARK_REQUEST =
  "SENSITIVE_VIP_PLAYER_MARK_UNMARK_REQUEST";
export const SENSITIVE_VIP_PLAYER_MARK_UNMARK_SUCCESS =
  "SENSITIVE_VIP_PLAYER_MARK_UNMARK_SUCCESS";
export const SENSITIVE_VIP_PLAYER_MARK_UNMARK_FAILURE =
  "SENSITIVE_VIP_PLAYER_MARK_UNMARK_FAILURE";

export const SENSITIVE_VIP_PLAYER_HISTORY_RELOAD =
  "SENSITIVE_VIP_PLAYER_HISTORY_RELOAD";
export const SENSITIVE_VIP_PLAYER_DETAILS_RELOAD =
  "SENSITIVE_VIP_PLAYER_DETAILS_RELOAD";

export const SENSITIVE_LIMIT_CONFIGURATION_DETAILS_REQUEST =
  "SENSITIVE_LIMIT_CONFIGURATION_DETAILS_REQUEST";
export const SENSITIVE_LIMIT_CONFIGURATION_DETAILS_SUCCESS =
  "SENSITIVE_LIMIT_CONFIGURATION_DETAILS_SUCCESS";
export const SENSITIVE_LIMIT_CONFIGURATION_DETAILS_FAILURE =
  "SENSITIVE_LIMIT_CONFIGURATION_DETAILS_FAILURE";
export const SENSITIVE_LIMIT_CONFIGURATION_DETAILS_RELOAD =
  "SENSITIVE_LIMIT_CONFIGURATION_DETAILS_RELOAD";

export const SENSITIVE_LIMIT_CONFIGURATION_EDIT_LIMIT_REQUEST =
  "SENSITIVE_LIMIT_CONFIGURATION_EDIT_LIMIT_REQUEST";
export const SENSITIVE_LIMIT_CONFIGURATION_EDIT_LIMIT_SUCCESS =
  "SENSITIVE_LIMIT_CONFIGURATION_EDIT_LIMIT_SUCCESS";
export const SENSITIVE_LIMIT_CONFIGURATION_EDIT_LIMIT_FAILURE =
  "SENSITIVE_LIMIT_CONFIGURATION_EDIT_LIMIT_FAILURE";

export const SENSITIVE_LIMIT_CONFIGURATION_ENABLE_DISABLE_LIMIT_REQUEST =
  "SENSITIVE_LIMIT_CONFIGURATION_ENABLE_DISABLE_LIMIT_REQUEST";
export const SENSITIVE_LIMIT_CONFIGURATION_ENABLE_DISABLE_LIMIT_SUCCESS =
  "SENSITIVE_LIMIT_CONFIGURATION_ENABLE_DISABLE_LIMIT_SUCCESS";
export const SENSITIVE_LIMIT_CONFIGURATION_ENABLE_DISABLE_LIMIT_FAILURE =
  "SENSITIVE_LIMIT_CONFIGURATION_ENABLE_DISABLE_LIMIT_FAILURE";

export const MANAGE_TEMPLATE_REQUEST = "MANAGE_TEMPLATE_REQUEST";
export const MANAGE_TEMPLATE_SUCCESS = "MANAGE_TEMPLATE_SUCCESS";
export const MANAGE_TEMPLATE_FAILURE = "MANAGE_TEMPLATE_FAILURE";
export const DELETE_TEMPLATE_REQUEST = "DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAILURE = "DELETE_TEMPLATE_FAILURE";
export const DELETE_CHANNEL_REQUEST = "DELETE_CHANNEL_REQUEST";
export const DELETE_CHANNEL_SUCCESS = "DELETE_CHANNEL_SUCCESS";
export const DELETE_CHANNEL_FAILURE = "DELETE_CHANNEL_FAILURE";
export const GET_CHANNEL_REQUEST = "GET_CHANNEL_REQUEST";
export const GET_CHANNEL_SUCCESS = "GET_CHANNEL_SUCCESS";
export const GET_CHANNEL_FAILURE = "GET_CHANNEL_FAILURE";
export const ADD_TEMPLATE_REQUEST = "ADD_TEMPLATE_REQUEST";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_FAILURE = "ADD_TEMPLATE_FAILURE";
export const LANGUAGES_CHANNEL_REQUEST = "LANGUAGES_CHANNEL_REQUEST";
export const LANGUAGES_CHANNEL_SUCCESS = "LANGUAGES_CHANNEL_SUCCESS";
export const LANGUAGES_CHANNEL_FAILURE = "LANGUAGES_CHANNEL_FAILURE";
export const HEADER_FOOTER_REQUEST = "HEADER_FOOTER_REQUEST";
export const HEADER_FOOTER_SUCCESS = "HEADER_FOOTER_SUCCESS";
export const HEADER_FOOTER_FAILURE = "HEADER_FOOTER_FAILURE";
export const SAVE_CHANNEL_REQUEST = "SAVE_CHANNEL_REQUEST";
export const SAVE_CHANNEL_SUCCESS = "SAVE_CHANNEL_SUCCESS";
export const SAVE_CHANNEL_FAILURE = "SAVE_CHANNEL_FAILURE";
export const VERSION_REQUEST = "VERSION_REQUEST";
export const VERSION_SUCCESS = "VERSION_SUCCESS";
export const VERSION_FAILURE = "VERSION_FAILURE";
export const MANAGE_TEMPLATE_VARIABLES_REQUEST =
  "MANAGE_TEMPLATE_VARIABLES_REQUEST";
export const MANAGE_TEMPLATE_VARIABLES_SUCCESS =
  "MANAGE_TEMPLATE_VARIABLES_SUCCESS";
export const MANAGE_TEMPLATE_VARIABLES_FAILURE =
  "MANAGE_TEMPLATE_VARIABLES_FAILURE";

export const OAUTH_PROVIDER_LOGIN_REQUEST = "OAUTH_PROVIDER_LOGIN_REQUEST";

export const LOAD_EXTERNAL_LOGIN_PLUGINS_REQUEST =
  "LOAD_EXTERNAL_LOGIN_PLUGINS_REQUEST";
export const LOAD_EXTERNAL_LOGIN_PLUGINS_SUCCESS =
  "LOAD_EXTERNAL_LOGIN_PLUGINS_SUCCESS";

export const VERSION_CHANNEL_DETAILS_REQUEST =
  "VERSION_CHANNEL_DETAILS_REQUEST";
export const VERSION_CHANNEL_DETAILS_SUCCESS =
  "VERSION_CHANNEL_DETAILS_SUCCESS";
export const VERSION_CHANNEL_DETAILS_FAILURE =
  "VERSION_CHANNEL_DETAILS_FAILURE";
export const RESET_CHANNEL = "RESET_CHANNEL";

export const ALL_LANGUAGES_REQUEST = "ALL_LANGUAGES_REQUEST";
export const ALL_LANGUAGES_SUCCESS = "ALL_LANGUAGES_SUCCESS";
export const ALL_LANGUAGES_FAILURE = "ALL_LANGUAGES_FAILURE";

export const EDIT_CHANNEL_REQUEST = "EDIT_CHANNEL_REQUEST";
export const EDIT_CHANNEL_SUCCESS = "EDIT_CHANNEL_SUCCESS";
export const EDIT_CHANNEL_FAILURE = "EDIT_CHANNEL_FAILURE";

export const TEMPLATE_LIST_RELOAD = "TEMPLATE_LIST_RELOAD";

export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";

export const SILENT_AUTHENTICATION_REQUEST = "SILENT_AUTHENTICATION_REQUEST";
export const SILENT_AUTHENTICATION_PAGE_REQUEST =
  "SILENT_AUTHENTICATION_PAGE_REQUEST";
export const SILENT_AUTHENTICATION_PAGE_SUCCESS =
  "SILENT_AUTHENTICATION_PAGE_SUCCESS";
export const SILENT_AUTHENTICATION_PAGE_FAILURE =
  "SILENT_AUTHENTICATION_PAGE_FAILURE";

export const COMMUNICATION_PREFERENCES_REQUEST =
  "COMMUNICATION_PREFERENCES_REQUEST";
export const COMMUNICATION_PREFERENCES_SUCCESS =
  "COMMUNICATION_PREFERENCES_SUCCESS";
export const COMMUNICATION_PREFERENCES_FAILURE =
  "COMMUNICATION_PREFERENCES_FAILURE";

export const UPDATE_COMMUNICATION_PREFERENCES_REQUEST =
  "UPDATE_COMMUNICATION_PREFERENCES_REQUEST";
export const UPDATE_COMMUNICATION_PREFERENCES_SUCCESS =
  "UPDATE_COMMUNICATION_PREFERENCES_SUCCESS";
export const UPDATE_COMMUNICATION_PREFERENCES_FAILURE =
  "UPDATE_COMMUNICATION_PREFERENCES_FAILURE";

export const NOTIFICATION_PREFERENCES_REQUEST =
  "NOTIFICATION_PREFERENCES_REQUEST";
export const NOTIFICATION_PREFERENCES_SUCCESS =
  "NOTIFICATION_PREFERENCES_SUCCESS";
export const NOTIFICATION_PREFERENCES_FAILURE =
  "NOTIFICATION_PREFERENCES_FAILURE";

export const PLAYER_PREFERENCES_REQUEST = "PLAYER_PREFERENCES_REQUEST";
export const PLAYER_PREFERENCES_SUCCESS = "PLAYER_PREFERENCES_SUCCESS";
export const PLAYER_PREFERENCES_FAILURE = "PLAYER_PREFERENCES_FAILURE";

export const UPDATE_PLAYER_PREFERENCES_REQUEST =
  "UPDATE_PLAYER_PREFERENCES_REQUEST";
export const UPDATE_PLAYER_PREFERENCES_SUCCESS =
  "UPDATE_PLAYER_PREFERENCES_SUCCESS";
export const UPDATE_PLAYER_PREFERENCES_FAILURE =
  "UPDATE_PLAYER_PREFERENCES_FAILURE";

export const UPDATE_NOTIFICATION_PREFERENCES_REQUEST =
  "UPDATE_NOTIFICATION_PREFERENCES_REQUEST";
export const UPDATE_NOTIFICATION_PREFERENCES_SUCCESS =
  "UPDATE_NOTIFICATION_PREFERENCES_SUCCESS";
export const UPDATE_NOTIFICATION_PREFERENCES_FAILURE =
  "UPDATE_NOTIFICATION_PREFERENCES_FAILURE";

export const CLOSING_ACCOUNTS_FILTERS_REQUEST =
  "CLOSING_ACCOUNTS_FILTERS_REQUEST";
export const CLOSING_ACCOUNTS_FILTERS_SUCCESS =
  "CLOSING_ACCOUNTS_FILTERS_SUCCESS";
export const CLOSING_ACCOUNTS_FILTERS_FAILURE =
  "CLOSING_ACCOUNTS_FILTERS_FAILURE";

export const CLOSING_ACCOUNTS_PLAYERS_LIST_REQUEST =
  "CLOSING_ACCOUNTS_PLAYERS_LIST_REQUEST";
export const CLOSING_ACCOUNTS_PLAYERS_LIST_SUCCESS =
  "CLOSING_ACCOUNTS_PLAYERS_LIST_SUCCESS";
export const CLOSING_ACCOUNTS_PLAYERS_LIST_FAILURE =
  "CLOSING_ACCOUNTS_PLAYERS_LIST_FAILURE";
export const CLOSING_ACCOUNTS_PLAYERS_LIST_RELOAD =
  "CLOSING_ACCOUNTS_PLAYERS_LIST_RELOAD";

export const CLOSING_ACCOUNTS_PLAYER_DETAIL_REQUEST =
  "CLOSING_ACCOUNTS_PLAYER_DETAIL_REQUEST";
export const CLOSING_ACCOUNTS_PLAYERS_DETAIL_SUCCESS =
  "CLOSING_ACCOUNTS_PLAYERS_DETAIL_SUCCESS";
export const CLOSING_ACCOUNTS_PLAYERS_DETAIL_FAILURE =
  "CLOSING_ACCOUNTS_PLAYERS_DETAIL_FAILURE";

export const CLOSING_ACCOUNTS_RETRY_TASK_REQUEST =
  "CLOSING_ACCOUNTS_RETRY_TASK_REQUEST";
export const CLOSING_ACCOUNTS_RETRY_TASK_SUCCESS =
  "CLOSING_ACCOUNTS_RETRY_TASKL_SUCCESS";
export const CLOSING_ACCOUNTS_RETRY_TASK_FAILURE =
  "CLOSING_ACCOUNTS_RETRY_TASKL_FAILURE";

export const PEP_PROCESS_REQUEST = "PEP_PROCESS_REQUEST";
export const PEP_PROCESS_SUCCESS = "PEP_PROCESS_SUCCESS";
export const PEP_PROCESS_FAILURE = "PEP_PROCESS_FAILURE";

export const PEP_SEARCH_CRITERIA_REQUEST = "PEP_SEARCH_CRITERIA_REQUEST";
export const PEP_SEARCH_CRITERIA_SUCCESS = "PEP_SEARCH_CRITERIA_SUCCESS";
export const PEP_SEARCH_CRITERIA_FAILURE = "PEP_SEARCH_CRITERIA_FAILURE";

export const PEP_SEARCH_REQUEST = "PEP_SEARCH_REQUEST";
export const PEP_SEARCH_SUCCESS = "PEP_SEARCH_SUCCESS";
export const PEP_SEARCH_FAILURE = "PEP_SEARCH_FAILURE";

export const EDIT_PEP_NOTES_REQUEST = "EDIT_PEP_NOTES_REQUEST";
export const EDIT_PEP_NOTES_SUCCESS = "EDIT_PEP_NOTES_SUCCESS";
export const EDIT_PEP_NOTES_FAILURE = "EDIT_PEP_NOTES_FAILURE";

export const STATUS_HISTORY_REQUEST = "STATUS_HISTORY_REQUEST";
export const STATUS_HISTORY_SUCCESS = "STATUS_HISTORY_SUCCESS";
export const STATUS_HISTORY_FAILURE = "STATUS_HISTORY_FAILURE";

export const DP_DOWNLOAD_REQUEST = "DP_DOWNLOAD_REQUEST";
export const DP_DOWNLOAD_SUCCESS = "DP_DOWNLOAD_SUCCESS";
export const DP_DOWNLOAD_FAILURE = "DP_DOWNLOAD_FAILURE";

export const LATEST_REGULATOR_STATUS_REQUEST =
  "LATEST_REGULATOR_STATUS_REQUEST";
export const LATEST_REGULATOR_STATUS_SUCCESS =
  "LATEST_REGULATOR_STATUS_SUCCESS";
export const LATEST_REGULATOR_STATUS_FAILED = "LATEST_REGULATOR_STATUS_FAILED";

export const GET_ACTIVE_LEVEL_GROUPS_REQUEST =
  "GET_ACTIVE_LEVEL_GROUPS_REQUEST";
export const GET_ACTIVE_LEVEL_GROUPS_SUCCESS =
  "GET_ACTIVE_LEVEL_GROUPS_SUCCESS";
export const GET_ACTIVE_LEVEL_GROUPS_FAILED = "GET_ACTIVE_LEVEL_GROUPS_FAILED";

//Dashboards id
export const HOME_DASHBOARD_ID = 7;
export const PLAYERS_DASHBOARD_ID = 2;
export const REWARDS_DASHBOARD_ID = 6;

export const STYLE_CURRENCY = "currency";
export const STYLE_LABEL = "label";

// Criteria Types
export const CRITERIA_TYPE_DATE = "date";
export const CRITERIA_TYPE_SELECT = "select";
export const CRITERIA_TYPE_DATERANGE = "daterange";
export const CRITERIA_TYPE_PERIOD = "period";
export const CRITERIA_TYPE_DATETIMERANGE = "datetimerange";

//Label Prefix
export const REWARDS_FORM_PREFIX = "label.rewardsForm.";

// Service Parameters
export const SERVICE_GROUP_ID = "5019";
export const CARD_PROXY_ID = "5022";
export const LEVEL = "5023";
export const PRINT_TYPE = "5024";
export const CURRENCY = "5025";
export const CARD_STATUS = "5026";
export const GIS_ACCOUNT_ACTIVATION_TIME = "5040";
export const CLAIM_CARD_ID = "5041";
export const AUTO_PAY = "5091";
export const AUTHENTICATION_TYPE = "5092";
export const PRINT_REQUEST_DATE = "5094";
export const PRINT_REASON = "5095";
export const BULK_REQUESTS_LIST_SUCCESS = "BULK_REQUESTS_LIST_SUCCESS";
export const BULK_REQUESTS_LIST_FAILURE = "BULK_REQUESTS_LIST_FAILURE";
export const BULK_REQUESTS_LIST_REQUEST = "BULK_REQUESTS_LIST_REQUEST";
export const BULK_REQUESTS_FILTERS_REQUEST = "BULK_REQUESTS_FILTERS_REQUEST";
export const BULK_REQUESTS_FILTERS_FAILURE = "BULK_REQUESTS_FILTERS_FAILURE";
export const BULK_REQUESTS_FILTERS_SUCCESS = "BULK_REQUESTS_FILTERS_SUCCESS";
export const ADD_BULK_REQUEST_REQUEST = "ADD_BULK_REQUEST_REQUEST";
export const ADD_BULK_REQUEST_SUCCESS = "ADD_BULK_REQUEST_SUCCESS";
export const ADD_BULK_REQUEST_FAILURE = "ADD_BULK_REQUEST_FAILURE";
export const BULK_REQUESTS_LIST_RELOAD = "BULK_REQUESTS_LIST_RELOAD";
export const BULK_REQUEST_TASK_TYPES_SUCCESS =
  "BULK_REQUEST_TASK_TYPES_SUCCESS";
export const BULK_REQUEST_TASK_TYPES_FAILURE =
  "BULK_REQUEST_TASK_TYPES_FAILURE";
export const BULK_REQUEST_TASK_TYPES_REQUEST =
  "BULK_REQUEST_TASK_TYPES_REQUEST";
export const BULK_REQUEST_TRANSACTIONS_REQUEST =
  "BULK_REQUEST_TRANSACTIONS_REQUEST";
export const BULK_REQUEST_TRANSACTIONS_SUCCESS =
  "BULK_REQUEST_TRANSACTIONS_SUCCESS";
export const BULK_REQUEST_TRANSACTIONS_FAILURE =
  "BULK_REQUEST_TRANSACTIONS_FAILURE";
export const START_EXPORT = 'START_EXPORT';
export const FINISH_EXPORT = 'FINISH_EXPORT';
export const EXPORT_DATA_TAKEN = 'EXPORT_DATA_TAKEN';
