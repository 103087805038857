import BadgeStatus from "../../../../Layouts/BadgeStatus/BadgeStatus";
import React from "react";
import {
  BULK_REQUESTS_FILTERS_FAILURE,
  BULK_REQUESTS_FILTERS_REQUEST,
  BULK_REQUESTS_FILTERS_SUCCESS,
  BULK_REQUESTS_LIST_FAILURE,
  BULK_REQUESTS_LIST_REQUEST,
  BULK_REQUESTS_LIST_SUCCESS,
  BULK_REQUEST_TASK_TYPES_FAILURE,
  BULK_REQUEST_TASK_TYPES_SUCCESS,
  BULK_REQUEST_TRANSACTIONS_FAILURE,
  BULK_REQUEST_TRANSACTIONS_REQUEST,
  BULK_REQUEST_TRANSACTIONS_SUCCESS,
} from "../../../constants";
import Moment from "react-moment";
import Translate from "../../../../Layouts/Translator/Translate";

export const labelPrefix = "label.admin.bulkRequests.";

export const getUploadStatusMapping = (status) => {
  switch (status) {
    case "UPLOADING":
      return { id: "1", label: "open" };
    case "ACQUIRED": {
      return {
        id: "2",
        label: "uploadSuccess",
      };
    }
    case "ERROR": {
      return {
        id: "5",
        label: "uploadError",
      };
    }
    default: {
      return {
        id: "1",
        label: "unknown",
      };
    }
  }
};

export const getProcessingStatusMapping = (status) => {
  switch (status) {
    case "TO_PROCESS":
      return {
        id: "1",
        label: "toProcess",
      };
    case "TO_RETRY": {
      return {
        id: "4",
        label: "inretry",
      };
    }
    case "IN_RETRY": {
      return {
        id: "4",
        label: "inretry",
      };
    }
    case "PROCESSING": {
      return {
        id: "13",
        label: "processing",
      };
    }
    case "DONE": {
      return {
        id: "2",
        label: "completed",
      };
    }
    case "ERROR": {
      return {
        id: "5",
        label: "inError",
      };
    }
    default: {
      return {
        id: "1",
        label: "unknown",
      };
    }
  }
};

export const getRequestStatusMapping = (status) => {
  switch (status) {
    case "TO_START":
      return {
        id: "1",
        label: "toStart",
      };
    case "ONGOING": {
      return {
        id: "13",
        label: "onGoing",
      };
    }
    case "COMPLETED_WITH_WARNING": {
      return {
        id: "4",
        label: "complWithWarning",
      };
    }
    case "COMPLETED_SUCCESFULLY": {
      return {
        id: "2",
        label: "completed",
      };
    }
    case "ERROR": {
      return {
        id: "5",
        label: "error",
      };
    }
    default: {
      return {
        id: "1",
        label: "unknown",
      };
    }
  }
};

const initialState = {
  bulkRequestsList: {
    data: null,
    isLoading: true,
    columns: [
      {
        field: "id",
        title: labelPrefix + "requestId",
      },
      {
        field: "requestName",
        title: labelPrefix + "requestName",
      },
      {
        field: "fileOriginalName",
        title: labelPrefix + "fileOriginalName",
      },
      {
        field: "taskType",
        title: labelPrefix + "taskType",
        render: (rowData) => {
          var taskType = rowData["taskType"];
          return <Translate needle={labelPrefix + "taskTypes." + taskType} />;
        },
      },
      {
        field: "uploadStatus",
        title: labelPrefix + "uploadStatus",
        render: (rowData) => {
          const statusMapped = getUploadStatusMapping(rowData["uploadStatus"]);
          return (
            <BadgeStatus
              id={statusMapped.id}
              label={statusMapped.label}
              fullWidth
              tooltip={true}
              justify={"center"}
            />
          );
        },
      },
      {
        field: "uploadLastResult",
        title: labelPrefix + "uploadLastResult",
      },
      {
        field: "processingStatus",
        title: labelPrefix + "processingStatus",
        render: (rowData) => {
          const statusMapped = getProcessingStatusMapping(
            rowData["processingStatus"]
          );
          return (
            <BadgeStatus
              id={statusMapped.id}
              label={statusMapped.label}
              fullWidth
              tooltip={true}
              justify={"center"}
            />
          );
        },
      },
      {
        field: "processingLastResult",
        title: labelPrefix + "processingLastResult",
        render: (rowData) => {
          if (
            rowData["processingLastResult"] &&
            rowData["processingLastResult"] != ""
          ) {
            const executionResult = rowData["processingLastResult"];
            return (
              <Translate
                needle={labelPrefix + "executionResults." + executionResult}
              />
            );
          }
        },
      },
      {
        field: "requestStatus",
        title: labelPrefix + "requestStatus",
        render: (rowData) => {
          const statusMapped = getRequestStatusMapping(
            rowData["requestStatus"]
          );
          return (
            <BadgeStatus
              id={statusMapped.id}
              label={statusMapped.label}
              fullWidth
              tooltip={true}
              justify={"center"}
            />
          );
        },
      },
      {
        field: "startDate",
        title: labelPrefix + "startDate",
        render: (rowData) => {
          if (rowData["startDate"]) {
            return <Moment format="L LT">{rowData["startDate"]}</Moment>;
          } else {
            return null;
          }
        },
      },
      {
        field: "endDate",
        title: labelPrefix + "endDate",
        render: (rowData) => {
          if (rowData["endDate"]) {
            return <Moment format="L LT">{rowData["endDate"]}</Moment>;
          } else {
            return null;
          }
        },
      },
      {
        field: "uploadRequester",
        title: labelPrefix + "uploadRequester",
      },
    ],
  },
  bulkRequestView: {
    fields: [
      { field: "id", title: labelPrefix + "requestId" },
      { field: "requestName", title: labelPrefix + "requestName" },
      { field: "fileOriginalName", title: labelPrefix + "fileOriginalName" },
      { field: "taskType", title: labelPrefix + "taskType" },
      {
        field: "insertDate",
        title: labelPrefix + "insertDate",
        render: (rowData) => {
          if (rowData["insertDate"]) {
            return <Moment format="L LT">{rowData["insertDate"]}</Moment>;
          } else {
            return null;
          }
        },
      },
      {
        field: "uploadStatus",
        title: labelPrefix + "uploadStatus",
        render: (rowData) => {
          const statusMapped = getUploadStatusMapping(rowData["uploadStatus"]);
          return (
            <BadgeStatus
              id={statusMapped.id}
              label={statusMapped.label}
              fullWidth
              tooltip={true}
              justify={"center"}
            />
          );
        },
      },
      { field: "uploadLastResult", title: labelPrefix + "uploadLastResult" },
      {
        field: "uploadStatusUpdateDate",
        title: labelPrefix + "uploadStatusUpdateDate",
        render: (rowData) => {
          if (rowData["uploadStatusUpdateDate"]) {
            return (
              <Moment format="L LT">{rowData["uploadStatusUpdateDate"]}</Moment>
            );
          } else {
            return null;
          }
        },
      },
      {
        field: "processingStatus",
        title: labelPrefix + "processingStatus",
        render: (rowData) => {
          const statusMapped = getProcessingStatusMapping(
            rowData["processingStatus"]
          );
          return (
            <BadgeStatus
              id={statusMapped.id}
              label={statusMapped.label}
              fullWidth
              tooltip={true}
              justify={"center"}
            />
          );
        },
      },
      {
        field: "processingLastResult",
        title: labelPrefix + "processingLastResult",
        render: (rowData) => {
          if (
            rowData["processingLastResult"] &&
            rowData["processingLastResult"] != ""
          ) {
            const executionResult = rowData["processingLastResult"];
            return (
              <Translate
                needle={labelPrefix + "executionResults." + executionResult}
              />
            );
          }
        },
      },
      {
        field: "processingStatusUpdateDate",
        title: labelPrefix + "processingStatusUpdateDate",
        render: (rowData) => {
          if (rowData["processingStatusUpdateDate"]) {
            return (
              <Moment format="L LT">
                {rowData["processingStatusUpdateDate"]}
              </Moment>
            );
          } else {
            return null;
          }
        },
      },
      {
        field: "requestStatus",
        title: labelPrefix + "requestStatus",
        render: (rowData) => {
          const statusMapped = getRequestStatusMapping(
            rowData["requestStatus"]
          );
          return (
            <BadgeStatus
              id={statusMapped.id}
              label={statusMapped.label}
              fullWidth
              tooltip={true}
              justify={"center"}
            />
          );
        },
      },
      {
        field: "startDate",
        title: labelPrefix + "startDate",
        render: (rowData) => {
          if (rowData["startDate"]) {
            return <Moment format="L LT">{rowData["startDate"]}</Moment>;
          } else {
            return null;
          }
        },
      },

      {
        field: "endDate",
        title: labelPrefix + "endDate",
        render: (rowData) => {
          if (rowData["endDate"]) {
            return <Moment format="L LT">{rowData["endDate"]}</Moment>;
          } else {
            return null;
          }
        },
      },
      { field: "uploadRequester", title: labelPrefix + "uploadRequester" },
      { field: "requestReason", title: labelPrefix + "requestReason" },
      { field: "muteNotification", title: labelPrefix + "muteNotification" },
    ],
  },
  bulkRequestsTransactions: {
    data: null,
    loading: true,
    columns: (t) => {
      return [
        {
          field: "bulkRequestKeyValue",
          title: labelPrefix + "bulkRequestKeyValue",
        },
        {
          field: "dateLastUpdate",
          title: labelPrefix + "dateLastUpdate",
          render: (rowData) => {
            if (rowData["dateLastUpdate"]) {
              return <Moment format="L LT">{rowData["dateLastUpdate"]}</Moment>;
            } else {
              return null;
            }
          },
        },
        {
          field: "playerComponentsList",
          title: labelPrefix + "executionResult",
          render: (rowData) => {
            if (
              rowData["playerComponentsList"] &&
              rowData["playerComponentsList"][0] &&
              rowData["playerComponentsList"][0]["executionResult"] &&
              rowData["playerComponentsList"][0]["executionResult"] != ""
            ) {
              const executionResult = rowData["playerComponentsList"][0][
                "executionResult"
              ]
                .split("#")[0]
                .trim();
              if (executionResult && executionResult != "") {
                return (
                  <Translate
                    needle={labelPrefix + "executionResults." + executionResult}
                  />
                );
              }
            } else {
              return null;
            }
          },
          getExportValueFn: (rowData) => {
            if (
              rowData["playerComponentsList"] &&
              rowData["playerComponentsList"][0] &&
              rowData["playerComponentsList"][0]["executionResult"] &&
              rowData["playerComponentsList"][0]["executionResult"] != ""
            ) {
              const executionResult = rowData["playerComponentsList"][0][
                "executionResult"
              ]
                .split("#")[0]
                .trim();
              return t({
                needle: labelPrefix + "executionResults." + executionResult,
              });
            } else {
              return null;
            }
          },
        },
      ];
    },
    Detailscol: [
      {
        field: "key",
        title: labelPrefix + "bulkRequestRowJsonDetailKey",
      },
      {
        field: "value",
        title: labelPrefix + "bulkRequestRowJsonDetailValue",
      },
    ],
  },
};

const BulkRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case BULK_REQUESTS_LIST_REQUEST: {
      return {
        ...state,
        bulkRequestsList: {
          ...state.bulkRequestsList,
          lastRequest: action,
          data: null,
          isLoading: true,
        },
      };
    }

    case BULK_REQUESTS_LIST_SUCCESS: {
      return {
        ...state,
        bulkRequestsList: {
          ...state.bulkRequestsList,
          data: action.payload,
          isLoading: false,
        },
      };
    }
    case BULK_REQUESTS_LIST_FAILURE: {
      return {
        ...state,
        bulkRequestsList: {
          ...state.bulkRequestsList,
          data: null,
          isLoading: false,
        },
      };
    }

    case BULK_REQUESTS_FILTERS_REQUEST: {
      return {
        ...state,
        bulkRequestsList: {
          ...state.bulkRequestsList,
          filters: [],
          filtersLoading: true,
        },
      };
    }

    case BULK_REQUESTS_FILTERS_SUCCESS: {
      return {
        ...state,
        bulkRequestsList: {
          ...state.bulkRequestsList,
          filters: action.payload,
          filtersLoading: false,
        },
      };
    }

    case BULK_REQUESTS_FILTERS_FAILURE: {
      return {
        ...state,
        bulkRequestsList: {
          ...state.bulkRequestsList,
          filters: null,
          filtersLoading: false,
        },
      };
    }

    case BULK_REQUEST_TRANSACTIONS_REQUEST: {
      return {
        ...state,
        bulkRequestsTransactions: {
          ...state.bulkRequestsTransactions,
          isLoading: true,
        },
      };
    }

    case BULK_REQUEST_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        bulkRequestsTransactions: {
          ...state.bulkRequestsTransactions,
          data: action.payload,
          loading: false,
        },
      };
    }

    case BULK_REQUEST_TRANSACTIONS_FAILURE: {
      return {
        ...state,
        bulkRequestsTransactions: {
          ...state.bulkRequestsTransactions,
          data: null,
          loading: false,
        },
      };
    }

    case BULK_REQUEST_TASK_TYPES_SUCCESS: {
      return {
        ...state,
        addBulkRequestForm: {
          ...state.addBulkRequestForm,
          taskTypesList: action.payload,
        },
      };
    }
    case BULK_REQUEST_TASK_TYPES_FAILURE: {
      return {
        ...state,
        taskTypesList: null,
      };
    }

    default: {
      return state;
    }
  }
};
export default BulkRequestsReducer;
