import ExternalUrlReducer from "./store/external-url/external-url-reducer";
import SendMailReducer from "./store/authentication/send-mail-reducer";
import PwRecoveryReducer from "./store/authentication/pw-recovery-reducer";
import PwresetReducer from "./store/authentication/pw-reset-reducer";
import AuthenticationReducer from "./store/authentication/sessioninfo-reducer";
import MainReducer from "./store/main-reducer";
import SideMenuReducer from "./store/tree-menu/side-menu-reducer";
import LanguageReducer from "./store/locale/languages-reducer";
import BookmarksReducer from "./store/bookmarks/bookmarks-reducer";
import PageActionsReducer from "./store/page-actions/page-actions-reducer";
import PlayerReducer from "./store/players/player-reducer";
import PepReducer from "./store/players/pep/pep-reducer";
import SnackbarsReducer from "./store/snackbars/snackbars-reducer";
import WidgetReducer from "./store/widget/widget-reducer";
import GamePlatformsReducer from "./store/game-platforms/game-platforms-reducer";
import PlayerGroupsReducer from "./store/players/player-groups/player-groups-reducer";
import ExpiringBonusReducer from "./store/players/bulk-rewards/expiring-bonus/expiring-bonus-reducer";
import GameCodesReducer from "./store/game-codes/game-codes-reducer";
import BulkOperationListReducer from "./store/players/bulk-rewards/bulk-operation-list/bulk-operation-list-reducer";
import LoginHistoryReducer from "./store/players/individual-player/login-history/login-history-reducer";
import CustomerAccountUpdateReducer from "./store/players/individual-player/customer-account-update/customer-account-update-reducer";
import ContractTypeListReducer from "./store/contract-type-list/contract-type-reducer";
import IndividualPlayerGroupsReducer from "./store/players/individual-player/player-groups/individual-player-groups-reducer";
import PlayerDetailReducer from "./store/players/individual-player/player-detail/player-detail-reducer";
import PlayerPaymentReducer from "./store/players/individual-player/payment/payment-reducer";
import AccountDetailsReducer from "./store/players/individual-player/account-details/account-details-reducer";
import BasicStepReducer from "./store/players/bulk-rewards/bulk-operation-add/basic-step/basic-step-reducer";
import AccountTransactionsReducer from "./store/players/individual-player/account-transactions/account-transactions-reducer";
import DirectNotificationsReducer from "./store/players/individual-player/direct-notifications/direct-notifications-reducer";
import ChannelsReducer from "./store/channels/channels-reducer";
import CommunicationHistoryReducer from "./store/players/individual-player/communication-history/communication-history-reducer";
import PlayerNotesReducer from "./store/players/individual-player/player-notes/player-notes-reducer";
import FinancialLimitsReducer from "./store/players/individual-player/responsible-game/financial-limits/financial-limits-reducer";
import PlayerLimitsReducer from "./store/players/individual-player/responsible-game/rgl-financial-limits/player-limits-reducer";
import ErrorPageRducer from "./store/error-page/error-page-reducer";
import PlayerFriendsReducer from "./store/players/individual-player/player-friends/player-friends-reducer";
import LinkedAccountsReducer from "./store/players/individual-player/linked-accounts/linked-accounts-reducer";
import CurrenciesReducer from "./store/currencies/currencies-reducer";
import SecondStepReducer from "./store/players/bulk-rewards/bulk-operation-add/second-step/second-step-reducer";
import KeyAccessManagementReducer from "./store/players/individual-player/key-access-management/key-access-management-reducer";
import IndividualPlayerConsentsReducer from "./store/players/individual-player/individual-consents/individual-consents-reducer";
import ExternalLinkedAccountsReducer from "./store/players/individual-player/external-linked-accounts/external-linked-accounts-reducer";
import SelfExclusionReducer from "./store/players/individual-player/self-exclusion/self-exclusion-reducer";
import NetworkLimitsReducer from "./store/players/individual-player/responsible-game/network-limits/network-limits-reducer";
import GameWagerLimitsReducer from "./store/players/individual-player/responsible-game/game-wager-limits/game-wager-limits-reducer";
import BonusAbuserHistoryReducer from "./store/players/individual-player/rewards/bonus-abuser-history/bonus-abuser-history-reducer";
import LoyaltyTransactionsReducer from "./store/players/individual-player/loyalty-transactions/loyalty-transactions-reducer";
import UserPreferencesReducer from "./store/user-preferences/user-preferences-reducer";
import BonusBagsReducer from "./store/players/individual-player/rewards/bonus-bags/bonus-bags-reducer";
import LoyaltyDetailsReducer from "./store/players/individual-player/loyalty-details/loyalty-details-reducer";
import LongRunningTaskReducer from "./store/players/individual-player/long-running-task/long-running-task-reducer";
import TaskTypesReducer from "./store/task-types/task-types-reducer";
import FreeSpinReducer from "./store/players/individual-player/free-spin/free-spin-reducer";
import GamesOverviewReducer from "./store/players/individual-player/games-overview/games-overview-reducer";
import TicketsReducer from "./store/players/individual-player/tickets/tickets-reducer";
import GameTransactionsReducer from "./store/players/individual-player/game-transactions/game-transactions-reducer";
import PlatformNicknamesReducer from "./store/players/individual-player/platform-nicknames/platform-nicknames-reducer";
import ReasonReducer from "./store/clubCard-Reasons/reasons-reducer";
import BarCodeReducer from "./store/clubCard-barCode/barCode_reducer";
import CampaignManagementReducer from "./store/rewards/campaigns/campaign-management/campaign-managemet-reducer";
import LimitConfigurationReducer from "./store/rewards/loyalty/limit-configuration/limit-configuration-reducer";
import LoyaltyTransactionTypesReducer from "./store/rewards/loyalty/loyalty-transaction-type-reducer";
import LoyaltyGameDomainsReducer from "./store/rewards/loyalty/loyalty-game-domains-reducer";
import ProgramReducer from "./store/rewards/loyalty/program/program-reducer";
import CommunicationsReducer from "./store/rewards/campaigns/communications/communications-reducer";
import ClubCardReducer from "./store/players/individual-player/club-card/club-card-reducer";
import KYCStatusReducer from "./store/players/individual-player/kyc-status/kyc-status-reducer";
import PromoCodeReducer from "./store/rewards/campaigns/promo-code/promo-code-reducer";
import BlacklistManagementReducer from "./store/rewards/campaigns/blacklist-management/blacklist-management-reducer";
import TaxablePrizeReducer from "./store/taxable-prize/taxable-prize-reducer";
import RafListReducer from "./store/rewards/raf/raf-list/raf-list-reducer";
import PlayerFavoritesReducer from "./store/players/individual-player/player-Favorites/player-favorites-reducer";
import NetworkBansAdminReducer from "./store/admin/player-configuration/network-bans/network-bans-reducer";
import GameCategoriesReducer from "./store/game-categories/game-categories-reducer";
import LocationsReducer from "./store/locations/locations-reducer";
import ModalConfigReducer from "./store/modal-config/modal-config-reducer";
import DeskReducer from "./store/desk/desk-reducer";
import ContactVerificationReducer from "./store/players/individual-player/contact-verification/contact-verification-reducer";
import PlayerContactsVerificationReducer from "./store/admin/system/player-contacts-verification/player-contacts-verification-reducer";
import ManageTemplateReducer from "./store/notifications/manage-template-reducer";
import ExternalLoginPluginReducer from "./store/authentication/external-login-plugin-reducer";
import SensitiveVipManagementReducer from "./store/players/individual-player/sensitive-vip-manegement/sensitive-vip-management-reducer";
import SensitiveLimitConfigurationReducer from "./store/admin/player-configuration/sensitive-limit-configuration/sensitive-limit-configuration-reducer";
import SilentLoginReducer from "./store/silent-login/silent-login-reducer";
import CommunicationPreferencesReducer from "./store/players/individual-player/communication-preferences/communication-preferences-reducer";
import NotificationPreferencesReducer from "./store/players/individual-player/notification-preferences/notification-preferences-reducer";
import PlayerPreferencesReducer from "./store/players/individual-player/player-preferences/player-preferences-reducer";
import SessionLimitsReducer from "./store/players/individual-player/responsible-game/session-limits/session-limits-reducer";
import ClosingAccountsReducer from "./store/closing-accounts/closing-accounts-reducer";
import PlayerDocumentsReducer from "./store/players/individual-player/player-documents/player-documents-reducer";
import StatusHistoryReducer from "./store/players/individual-player/status-history/status-history-reducer";
import BlocklistReducer from "./store/players/individual-player/blocklist/blocklist-reducer";
import LotteryBlocklistReducer from "./store/admin/player-configuration/lottery-blocklist/lottery-blocklist-reducer";
import BulkRequestsReducer from "./store/admin/player-configuration/bulk-requests/bulk-requests-reducer";
import UsersReducer from "./store/admin/security/users/users-reducer";
import AdminLimitConfigurationReducer from "./store/admin/security/limitConfiguration/limit-configuration-reducer";
import SecuritySettingsReducer from "./store/admin/security/securitySettings/security-settings-reducer";
import LatestRegulatorStatusReducer from "./store/players/individual-player/latest-regulator-status/latest-regulator-status-reducer";
import AssignRewardReducer from "./store/assign-reward/assign-reward-reducer";
const reducers = {
  ExternalUrlReducer,
  AuthenticationReducer,
  SendMailReducer,
  PwRecoveryReducer,
  PwresetReducer,
  MainReducer,
  SideMenuReducer,
  LanguageReducer,
  BookmarksReducer,
  PageActionsReducer,
  PlayerReducer,
  PepReducer,
  SnackbarsReducer,
  WidgetReducer,
  GamePlatformsReducer,
  PlayerGroupsReducer,
  ExpiringBonusReducer,
  GameCodesReducer,
  BulkOperationListReducer,
  LoginHistoryReducer,
  CustomerAccountUpdateReducer,
  ContractTypeListReducer,
  IndividualPlayerGroupsReducer,
  PlayerDetailReducer,
  PlayerPaymentReducer,
  AccountDetailsReducer,
  BasicStepReducer,
  AccountTransactionsReducer,
  DirectNotificationsReducer,
  ChannelsReducer,
  CommunicationHistoryReducer,
  PlayerNotesReducer,
  FinancialLimitsReducer,
  PlayerLimitsReducer,
  ErrorPageRducer,
  PlayerFriendsReducer,
  LinkedAccountsReducer,
  CurrenciesReducer,
  SecondStepReducer,
  KeyAccessManagementReducer,
  IndividualPlayerConsentsReducer,
  ExternalLinkedAccountsReducer,
  SelfExclusionReducer,
  NetworkLimitsReducer,
  GameWagerLimitsReducer,
  BonusAbuserHistoryReducer,
  LoyaltyTransactionsReducer,
  UserPreferencesReducer,
  BonusBagsReducer,
  LoyaltyDetailsReducer,
  LongRunningTaskReducer,
  TaskTypesReducer,
  FreeSpinReducer,
  GamesOverviewReducer,
  TicketsReducer,
  GameTransactionsReducer,
  PlatformNicknamesReducer,
  ReasonReducer,
  CampaignManagementReducer,
  LimitConfigurationReducer,
  LoyaltyTransactionTypesReducer,
  LoyaltyGameDomainsReducer,
  ProgramReducer,
  CommunicationsReducer,
  BarCodeReducer,
  ClubCardReducer,
  KYCStatusReducer,
  PromoCodeReducer,
  BlacklistManagementReducer,
  TaxablePrizeReducer,
  RafListReducer,
  PlayerFavoritesReducer,
  NetworkBansAdminReducer,
  GameCategoriesReducer,
  LocationsReducer,
  ModalConfigReducer,
  ContactVerificationReducer,
  DeskReducer,
  PlayerContactsVerificationReducer,
  ManageTemplateReducer,
  ExternalLoginPluginReducer,
  SensitiveVipManagementReducer,
  SensitiveLimitConfigurationReducer,
  SilentLoginReducer,
  CommunicationPreferencesReducer,
  NotificationPreferencesReducer,
  PlayerPreferencesReducer,
  SessionLimitsReducer,
  ClosingAccountsReducer,
  PlayerDocumentsReducer,
  StatusHistoryReducer,
  BlocklistReducer,
  LotteryBlocklistReducer,
  BulkRequestsReducer,
  UsersReducer,
  AdminLimitConfigurationReducer,
  SecuritySettingsReducer,
  LatestRegulatorStatusReducer,
  AssignRewardReducer,
};

export default reducers;
