import React from "react";
import { Form, Formik, validateYupSchema, yupToFormErrors } from "formik";
import { useSession } from "../../../../utilities/hooks/useSession";
import { useDispatch } from "react-redux";
import {
  getInitialValues,
  getRequiredValidation,
  getValidationByType,
  getValidationSchema,
  isFieldDisabled,
  isFieldHidden,
} from "../../../../Layouts/form/utils";
import { labelPrefix } from "../../../../store/players/individual-player/self-exclusion/self-exclusion-reducer";
import { useTranslator } from "../../../../utilities/hooks/useTranslator";
import {
  loadSelfExclusion,
  updateSelfExclusion,
} from "../../../../store/players/individual-player/self-exclusion/actions";
import { Box, Button, CardActions } from "@material-ui/core";
import { FormField } from "../../../../Layouts/form";
import { useRouteMatch } from "react-router-dom";
import moment from "moment";
import Translate from "../../../../Layouts/Translator/Translate";

const AddSelfExclusion = ({ cancelCb }) => {
  const t = useTranslator();
  const {
    brandSelected,
    partnerSelected,
    selfexclusionMinDate,
    selfexclusionMinDateType,
    selfexclusionMaxDate,
    selfexclusionMaxDateType,
  } = useSession();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const playerId = match.params.id;
  const minDate = moment().add(selfexclusionMinDate, selfexclusionMinDateType);

  const maxDate = moment().add(selfexclusionMaxDate, selfexclusionMaxDateType);

  const formFields = {
    endDate: {
      name: "endDate",
      type: "DateTimePicker",
      initialValue: null,
      validation: getRequiredValidation(
        getValidationByType("DateTimePicker"),
        "DateTimePicker",
        labelPrefix,
        "endDate"
      )
        .min(
          minDate.startOf("day").valueOf(),
          <Translate
            needle={"errors.minDate"}
            variables={[moment(minDate).format("L LT")]}
          />
        )
        .max(
          maxDate.startOf("day").valueOf(),
          <Translate
            needle={"errors.maxDate"}
            variables={[moment(maxDate).format("L LT")]}
          />
        ),
    },
    reason: {
      name: "reason",
      type: "Text",
      multiline: true,
      rows: 4,
      optional: true,
    },
  };

  const initialValues = getInitialValues(formFields);
  const validationSchema = getValidationSchema(labelPrefix, formFields);

  const handleSubmit = (values) => {
    const body = { playerSelfExcluded: true };
    body.selfExclusionTime = values.endDate;
    body.reason = values.reason;
    dispatch(
      updateSelfExclusion(
        brandSelected,
        partnerSelected,
        playerId,
        body,
        () => {
          dispatch(loadSelfExclusion());
        }
      )
    );
    handleCancel();
  };

  const handleCancel = () => {
    cancelCb();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={(values) => {
        return validateYupSchema(values, validationSchema, false, values)
          .then(() => ({}))
          .catch((err) => {
            return yupToFormErrors(err);
          });
      }}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ isSubmitting, values }) => {
        return (
          <Form>
            <Box p={1}>
              {Object.keys(formFields)
                .filter(
                  (fieldName) => !isFieldHidden(formFields[fieldName], values)
                )
                .map((fieldName) => {
                  const field = { ...formFields[fieldName] };
                  field.disabled = isFieldDisabled(field, values);
                  field.label = t({ needle: labelPrefix + field.name });

                  if (field.type === "DateTimePicker") {
                    field.minDate = minDate.startOf("day").valueOf();
                    field.maxDate = maxDate.startOf("day").valueOf();
                  }
                  return (
                    <Box key={fieldName} maxWidth={"initial"}>
                      <FormField fullWidth {...field} />
                    </Box>
                  );
                })}
            </Box>
            <CardActions className={"greyActions"}>
              <Box flex={1} display={"flex"} justifyContent={"flex-end"}>
                <Box width={120}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      handleCancel();
                    }}
                  >
                    {t({ needle: "button.cancel" })}
                  </Button>
                </Box>

                <Box ml={2} width={120}>
                  <Button
                    type={"submit"}
                    fullWidth
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                  >
                    {t({ needle: "button.save" })}
                  </Button>
                </Box>
              </Box>
            </CardActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddSelfExclusion;
