import { call, put } from "redux-saga/effects";
import {
  DELETE_PLAYERS_GROUP_REQUEST,
  GO_TO_ERROR_PAGE,
  PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_CRITERIA_REQUEST,
  PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_CRITERIA_SUCCESS,
  PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_FAILURE,
  PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_REQUEST,
  PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_SUCCESS,
  PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_FAILURE,
  PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_REQUEST,
  PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_SUCCESS,
  PLAYERS_GROUP_CREATE_REQUEST,
  PLAYERS_GROUP_DELETE_FAILURE,
  PLAYERS_GROUP_LIST_FAILURE,
  PLAYERS_GROUP_LIST_REQUEST,
  PLAYERS_GROUP_LIST_SUCCESS,
  PLAYERS_GROUP_VIEW_FAILURE,
  PLAYERS_GROUP_VIEW_REQUEST,
  PLAYERS_GROUP_VIEW_SUCCESS,
} from "../../constants";
import playerGroupsService from "./player-groups-service";
import { takeEvery, takeLatest } from "@redux-saga/core/effects";
import { enqueueSnackbar } from "../../snackbars/actions";

const loadUploadHistorySearchCriteriaFields = function* () {
  try {
    const data = yield call(
      playerGroupsService.loadUploadHistorySearchCriteria
    );
    yield put({
      type: PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_CRITERIA_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error(e);
  }
};

const loadUploadHistory = function* (action) {
  try {
    const data = yield call(
      playerGroupsService.loadUploadHistory,
      action.searchParams
    );
    yield put({
      type: PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("uploadHistory", e);
    yield put({
      type: PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_FAILURE,
    });
  }
};

const loadUploadHistoryView = function* (action) {
  try {
    const data = yield call(
      playerGroupsService.loadUploadHistoryView,
      action.process,
      action.version,
      action.query,
      action.brandId,
      action.partnerId
    );
    yield put({
      type: PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("uploadHistoryView", e);
    yield put({
      type: PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_FAILURE,
    });
    yield put({
      type: GO_TO_ERROR_PAGE,
      payload: action.process,
    });
  }
};

const playerGroupList = function* (action) {
  try {
    const data = yield call(
      playerGroupsService.playerGroupList,
      action.brand,
      action.partner,
      action.force
    );
    yield put({
      type: PLAYERS_GROUP_LIST_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("PlayerGroupList", e);
    yield put({
      type: PLAYERS_GROUP_LIST_FAILURE,
    });
  }
};

const deletePlayerGroup = function* (action) {
  try {
    yield call(
      playerGroupsService.deletePlayerGroup,
      action.profileName,
      action.brand,
      action.partner
    );
    yield put({
      type: PLAYERS_GROUP_LIST_REQUEST,
      brand: action.brand,
      partner: action.partner,
      force: true,
    });
    yield put(
      enqueueSnackbar({
        message: {
          needle: "ok.deletePlayerGroup",
        },
        options: {
          key: "DeletePlayerGroup_success",
          variant: "success",
        },
      })
    );
  } catch (e) {
    console.error("DeletePlayerGroup", e);
    yield put({
      type: PLAYERS_GROUP_DELETE_FAILURE,
    });
  }
};

const playerGroupView = function* (action) {
  try {
    const data = yield call(
      playerGroupsService.playerGroupView,
      action.contractId,
      action.brand,
      action.partner,
      action.query
    );
    yield put({
      type: PLAYERS_GROUP_VIEW_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.error("PlayerGroupView", e);
    yield put({
      type: PLAYERS_GROUP_VIEW_FAILURE,
    });
    yield put({
      type: GO_TO_ERROR_PAGE,
      payload: action.contractId,
    });
  }
};

const createPlayerGroup = function* (action) {
  try {
    yield call(playerGroupsService.addPlayerGroup, action);
    yield put(
      enqueueSnackbar({
        message: {
          needle: "label.playerGroupCreate.insertSuccess",
        },
        options: {
          key: "playerGroupAdd",
          variant: "success",
          autoHideDuration: 8000,
        },
      })
    );

    yield put({
      type: PLAYERS_GROUP_LIST_REQUEST,
      brand: action.brand.id,
      partner: action.partner.id,
    });
    action.cb && typeof action.cb === "function" && action.cb();
  } catch (e) {}
};

const PlayerGroupsSaga = function* externalUrlSaga() {
  yield takeLatest(
    PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_CRITERIA_REQUEST,
    loadUploadHistorySearchCriteriaFields
  );
  yield takeLatest(
    PLAYER_GROUPS_UPLOAD_HISTORY_SEARCH_REQUEST,
    loadUploadHistory
  );
  yield takeLatest(PLAYERS_GROUP_LIST_REQUEST, playerGroupList);
  yield takeLatest(DELETE_PLAYERS_GROUP_REQUEST, deletePlayerGroup);
  yield takeLatest(PLAYERS_GROUP_VIEW_REQUEST, playerGroupView);
  yield takeLatest(
    PLAYER_GROUPS_UPLOAD_HISTORY_VIEW_REQUEST,
    loadUploadHistoryView
  );
  yield takeEvery(PLAYERS_GROUP_CREATE_REQUEST, createPlayerGroup);
};

export default PlayerGroupsSaga;
