import axios from "axios";
import { CRITERIA_TYPE_DATE, CRITERIA_TYPE_SELECT } from "../../../constants";
import searchUtils from "../../../../utilities/searchUtils";
import { labelPrefix } from "./campaign-managemet-reducer";

class CampaignManagementService {
  getListFilter = async (ganttView) => {
    const filter = [
      {
        name: "campaignId",
        label: labelPrefix + "campaignId",
        selected: true,
      },
      {
        name: "name",
        label: labelPrefix + "name",
      },
      {
        name: "userCreator",
        label: labelPrefix + "userCreator",
      },
      {
        name: "campaignTags",
        label: labelPrefix + "campaignTags",
      },
      {
        name: "userApprove",
        label: labelPrefix + "userApprove",
      },
    ];

    const rewardTypes = await this.getRewardTypes();
    if (rewardTypes) {
      let rewardFilter = {
        name: "rewardType",
        label: labelPrefix + "rewardType",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.select",
        options: rewardTypes.map((rewardType) => {
          return {
            label: "label.rewardType." + rewardType.rewardTypeId,
            value: rewardType.rewardTypeId,
          };
        }),
      };

      filter.push(rewardFilter);
    }

    const eventTypes = await this.getEventTypes();
    if (eventTypes) {
      let eventFilter = {
        name: "eventType",
        label: labelPrefix + "eventType",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.multiselect",
        multiple: true,
        options: eventTypes.map((eventType) => {
          return {
            label: "label.eventType." + eventType.eventTypeId,
            value: eventType.eventTypeId,
          };
        }),
      };

      filter.push(eventFilter);
    }

    const statuses = await this.getStatuses();
    if (statuses) {
      let statusesFilter = {
        name: "campaignStatuses",
        label: labelPrefix + "campaignStatuses",
        type: CRITERIA_TYPE_SELECT,
        placeholder: "label.placeholders.multiselect",
        multiple: true,
        options: statuses.map((statute) => {
          return {
            label: "label.campaignStatus." + statute.id,
            value: statute.id,
          };
        }),
      };

      filter.push(statusesFilter);
    }

    filter.push(
      {
        name: "creationDate",
        label: labelPrefix + "creationDate",
        type: "singleDateRange",
        placeholder: "label.placeholders.date",
        disabled: ganttView,
      },
      {
        name: "lastUpdateDate",
        label: labelPrefix + "lastUpdateDate",
        type: "singleDateRange",
        placeholder: "label.placeholders.date",
        disabled: ganttView,
      },
      {
        name: "activationDate",
        label: labelPrefix + "activationDate",
        type: "singleDateRange",
        placeholder: "label.placeholders.date",
        disabled: ganttView,
      },
      {
        name: "expirationDate",
        label: labelPrefix + "expirationDate",
        type: "singleDateRange",
        placeholder: "label.placeholders.date",
        disabled: ganttView,
      }
    );

    return {
      filter,
      key: ganttView ? "ganttView" : "tableView",
    };
  };

  getStatuses = async () => {
    const url = "/gov/api/rest/v2/campaign/statuses";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting statuses");
  };

  getRewardTypes = async () => {
    const url = "/gov/api/rest/v2/campaign/rewardTypes";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting rewardTypes");
  };

  getEventTypes = async () => {
    const url = "/gov/api/rest/v2/campaign/eventTypes";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting eventTypes");
  };

  getRewardEventTypes = async (brand, partner) => {
    const url = "/gov/api/rest/v2/campaign/rewardEventTypes";
    const response = await axios.get(url, { params: { brand, partner } });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting rewardEventTypes");
  };

  getListData = async (brandId, parnterId, searchTerms) => {
    const url = "/gov/api/rest/v2/campaigns";
    const terms = searchUtils.sanitizeTerms(searchTerms);

    const params = {
      activationDateFrom: terms?.activationDate?.from,
      activationDateTo: terms?.activationDate?.to,
      creationDateFrom: terms?.creationDate?.from,
      creationDateTo: terms?.creationDate?.to,
      lastUpdateDateFrom: terms?.lastUpdateDate?.from,
      lastUpdateDateTo: terms?.lastUpdateDate?.to,
      expirationDateFrom: terms?.expirationDate?.from,
      expirationDateTo:terms?.expirationDate?.to
    }
    delete terms.activationDate
    delete terms.creationDate
    delete terms.lastUpdateDate
    delete terms.expirationDate

    const response = await axios.get(url, {
      params: {
        brand: brandId,
        partner: parnterId,
        ...terms,
        ...params
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response campaign managememt list");
  };

  getExclusionCampaignForPlayerAwarding = async (brand, partner) => {
    const url = "/gov/api/rest/v2/campaign/exclusionCampaignForPlayerAwarding";
    const response = await axios.get(url, { params: { brand, partner } });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting exclusionCampaignForPlayerAwarding");
  };

  getBudgetTypes = async () => {
    const url = "/gov/api/rest/v2/campaign/campaignBudgetTypes";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting budgetTypes");
  };

  async getCampaignDetails(campaignId) {
    const response = await axios.get(
      `/gov/api/rest/v2/campaign/detail/${campaignId}`
    );

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response campaign details");
  }

  async getBudgetDetails(brand, partner, campaignId, pending) {
    const response = await axios.get(
      `/gov/api/rest/v2/campaign/campaignPlayerRewards?campaignId=${campaignId}&brand=${brand}&partner=${partner}&pending=${pending}`
    );

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response campaign budget details");
  }

  async getRefDetails(brand, partner, campaignId, pending) {
    const response = await axios.get(
      `/gov/api/rest/v2/campaign/campaignPlayerReferrerRewards?campaignId=${campaignId}&brand=${brand}&partner=${partner}&pending=${pending}`
    );

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response campaign ref details");
  }

  async getPromoCodesDetails(brand, partner, campaignId, status) {
    const response = await axios.get(
      `/gov/api/rest/v2/campaign/campaignPlayerPromoCode?campaignId=${campaignId}&brand=${brand}&partner=${partner}&status=${status}`
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response campaign promo codes details");
  }

  getTags = async (brand, partner) => {
    const url = "/gov/api/rest/v1/campaign/tags";
    const body = {
      q: "*:*",
      brandId: brand,
      partnerId: partner,
      rows: 100,
      start: 0,
    };
    const response = await axios.post(url, body);

    if (response && response.data) {
      return response.data.collection.map((it) => it.NAME);
    }
    throw Error("Error getting campaign tags");
  };

  getParameterTypeOperator = async () => {
    const url = "/gov/api/rest/v2/campaign/campaignParameterTypeOperatorMap";
    const response = await axios.get(url);
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting campaign parameter type operator map");
  };

  getParameterDefinitions = async (brand, partner, eventTypeList, step) => {
    const url = "/gov/api/rest/v2/campaign/campaignParametersDefinition";
    const response = await axios.get(url, {
      params: {
        brand,
        partner,
        eventTypeList: eventTypeList.join(","),
        step,
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting campaign parameter definitions");
  };

  async changeStatus(brand, partner, campaignId, status, force) {
    let url = `/gov/api/rest/v2/campaign/changeStatus/${campaignId}?brand=${brand}&partner=${partner}&status=${status}&force=${force}`;
    const response = await axios.put(url);

    if (response == null || response.status !== 204) {
      throw Error("Invalid response account transaction " + status);
    }
  }

  async acceptanceType() {
    let url = "/gov/api/rest/v2/campaign/acceptanceTypes";
    const response = await axios.get(url);

    if (response && response.data) {
      let cutOut = response.data.splice(2, 1)[0];
      response.data.splice(3, 0, cutOut);
      return response.data;
    }
    throw Error("Error getting campaign acceptance types");
  }

  async getCommunications(brand, partner, hasPlayerGroup) {
    const response = await axios.get(
      `/gov/api/rest/v2/communications/campaign?hasPlayerGroup=${hasPlayerGroup}&brand=${brand}&partner=${partner}`
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response campaign comminucations");
  }

  getRewardRule = async (brand, partner, eventType, rewardType, step) => {
    const url = "/gov/api/rest/v2/campaign/campaignAvailableRules";

    const response = await axios.get(url, {
      params: {
        brand,
        partner,
        eventTypeList: eventType.join(","),
        rewardType,
        step,
      },
    });
    if (response && response.data) {
      return response.data;
    }
    throw Error("Error getting campaign reward rules");
  };

  saveCampaign = async (body) => {
    let url = "/gov/api/rest/v2/campaign";
    const { campaignId, isLight } = body;

    if (campaignId != null) {
      if (isLight) {
        url = url + "/light";
      }
      url = url + "/" + campaignId;
    }

    const response = await axios({
      method: campaignId != null ? "put" : "post",
      url,
      data: body,
    });

    if (response == null || response.status !== 204) {
      throw Error("Invalid response for saving Campaign");
    }
  };

  getCampaignFullDetails = async (brand, partner, campaignId) => {
    const url = `/gov/api/rest/v2/campaign/${campaignId}`;
    const response = await axios.get(url, {
      params: {
        brand,
        partner,
      },
    });

    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response for campaign details");
  };

  getCustomRewardDefinitions = async (brand, partner, rewardType) => {
    const response = await axios.get(
      `/gov/api/rest/v2/campaign/campaignCustomRewardParameters?brand=${brand}&partner=${partner}&rewardType=${rewardType}`
    );
    if (response && response.data) {
      return response.data;
    }
    throw Error("Invalid response custom reward definitions");
  };
}

const campaignManagementService = new CampaignManagementService();
export default campaignManagementService;
